import React from "react";
import { TiAttachmentOutline } from "react-icons/ti";
let buttonTypes = {
  default:
    "bg-[#4a7aae] rounded-lg px-3 py-[3px] text-base relative  flex items-center text-white font-semibold",
  addNew:
    "gradient-bg bg-buttonBg  rounded-lg py-[3px] px-3 max-w-[220px] font-medium mr-2 text-Poppins relative  flex items-center text-white font-semibold",
  cancel:
    "  border-[#4a7aae] border rounded-lg px-3 py-[2px] text-base  text-[#4a7aae] flex items-center  font-semibold",
  file: "bg-buttonBg  rounded-lg pl-4 pe-2 py-[2px] text-base relative  flex items-center text-white font-semibold",
};
const CustomButton = ({ label, buttonType, ...props }) => {
  return (
    <>
      {/* {rights[label]&& */}
      <button
        className={`${
          buttonTypes[buttonType] || buttonTypes.default
        } transition-all ease-in-out flex flex-row text-sm `}
        {...props}
      >
        {label}

        {buttonType === "file" && (
          // <span
          //   className="bg-white text-orange-500 w-7 text-[23px] ml-2 h-7
          //  flex items-center justify-center   font-bold rounded-full"
          // >
          //   +
          // </span>
          <TiAttachmentOutline className=" text-xl ml-1" />
        )}

        {/* {  buttonType==='downloadExcel' &&<img className="w-7 ml-3" src={logo} />} */}
      </button>
      {/* } */}
    </>
  );
};

export default CustomButton;
