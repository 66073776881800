import React from "react";

export const HeadingCard = ({ title, component }) => {
  return (
    <div
      className="max-h-[350px] flex flex-col shadow-sm rounded-md 
    transition-all duration-300 hover:shadow-2xl border  px-2 py-3"
    >
      <h2 className="text-xl font-semibold py-2 px-3  border-b ">{title}</h2>
      {component}
    </div>
  );
};
