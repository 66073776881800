import React, { useEffect, useState } from "react";
import { Card } from "./Card";
import { VscGraph } from "react-icons/vsc";
import { PieCharts } from "../chart/PieCharts";
import { HeadingCard } from "./HeadingCard";
import { Header } from "../../SearchedTickect/Header";
import { callApi } from "../../common/Apicall";

const Category = [
  { label: "Support Task", totalCount: 8893, id: 99 },
  { label: "Information", totalCount: 13579 },
  { label: "Issue/Bug", totalCount: 8523 },
  { label: "Enhancement", totalCount: 7970 },
  { label: "Suggestion", totalCount: 7970 },
  { label: "Migration", totalCount: 7970 },

  // Add more data as needed
];
const convertFunction = (data) => {
  return data?.map((i) => ({
    label: i.Priority,
    totalCount: i.PriorityCount,
    id: i.PriorityId,
  }));
};

const status = (data) => {
  return data?.map((i) => ({
    label: i.Status,
    totalCount: i.StatusCount,
    id: i.StatusID,
  }));
};

const assignees = (data) => {
  return data?.map((i) => ({
    label: i.AssigneeName,
    totalCount: i.TotalTasksPerAssignee,
    id: i.AssigneeID,
  }));
};

const Department = (data) => {
  return data?.map((i) => ({
    label: i.DepartmentName,
    totalCount: i.TotalTasksPerDepartment,
    id: i.DepartmentId,
  }));
};

const Team = (data) => {
  return data?.map((i) => ({
    label: i.TeamName,
    totalCount: i.TotalTasksPerTeam,
    id: i.TeamID,
  }));
};

const Dashboard = ({ handleChange }) => {
  const URL = process.env.REACT_APP_URL;
  const [state, setState] = useState({
    addCardPopup: false,
    department: [],
    assignee: [],
    priority: [],
    status: [],
    team: [],
    counts: {},
    isLoading: true,
  });

  useEffect(() => {
    setState((prev) => ({ ...prev, isLoading: true }));

    Promise.all([
      callApi(URL + "/Dashboard/GetTasksByDepartment", "GET"),
      callApi(URL + "/Dashboard/GetTasksByAssignee", "GET"),
      callApi(URL + "/Dashboard/GetTasksByPriority", "GET"),
      callApi(URL + "/Dashboard/GetTasksByStatus", "GET"),
      callApi(URL + "/Dashboard/GetTasksByTeams", "GET"),
      callApi(URL + "/Team/GetOverAllTotalCount", "GET"),
    ])
      .then((res) => {
        setState((prev) => ({
          ...prev,
          isLoading: false,
          department: res[0]?.data || [],
          assignee: res[1]?.data || [],
          priority: res[2]?.data || [],
          status: res[3]?.data || [],
          team: res[4]?.data || [],
          counts: res[5],
        }));
      })
      .catch((err) => {
        setState((prev) => ({ ...prev, isLoading: false }));
      });
  }, [URL]);

  const Component = (data, name) => (
    <div className="grid grid-cols-1 sm:grid-cols-2 p-4 max-h-[349px] overflow-auto">
      {data?.map((i, index) => (
        <div
          onClick={() => handleChange(name, i.id)}
          className="group flex items-center py-2 text-sm cursor-pointer flex-wrap"
          key={index}
        >
          <span className="border ml-2 h-8 min-w-[50px] border-sideNavbg group-hover:text-white group-hover:bg-sideNavbg rounded-full me-2 flex items-center justify-center">
            {i.totalCount.toLocaleString()} {/* Visible total count */}
          </span>
          <span className="group-hover:text-sideNavbg text-sm truncate">
            {i.label}
          </span>
        </div>
      ))}
    </div>
  );

  const singleComponent = (data, name) => (
    <div className="grid grid-cols-1 sm:grid-cols-2 px-1 max-h-[349px] overflow-auto">
      {data?.map((i, index) => (
        <div
          onClick={() => handleChange(name, i.id)}
          className="group flex items-center py-2 text-sm cursor-pointer flex-wrap"
          key={index}
        >
          <span className="border ml-2 h-8 min-w-[50px] border-sideNavbg group-hover:text-white group-hover:bg-sideNavbg rounded-full me-2 flex items-center justify-center">
            {i.totalCount.toLocaleString()} {/* Visible total count */}
          </span>
          <span className="group-hover:text-sideNavbg text-sm truncate">
            {i.label}
          </span>
        </div>
      ))}
    </div>
  );
  const singleassigneComponent = (data, name) => (
    <div className="grid grid-cols-1 sm:grid-cols-4 px-1 max-h-[349px] overflow-auto">
      {data?.map((i, index) => (
        <div
          onClick={() => handleChange(name, i.id)}
          className="group flex items-center py-2 text-sm cursor-pointer flex-wrap"
          key={index}
        >
          <span className="border ml-2 h-8 min-w-[50px] border-sideNavbg group-hover:text-white group-hover:bg-sideNavbg rounded-full me-2 flex items-center justify-center">
            {i.totalCount.toLocaleString()} {/* Visible total count */}
          </span>
          <span className="group-hover:text-sideNavbg text-sm truncate">
            {i.label}
          </span>
        </div>
      ))}
    </div>
  );

  return (
    <>
      <Header />
      <div className="bg-white px-4 sm:px-8 lg:px-16 max-h-[90vh] overflow-y-auto">
        <div className="mb-8">
          <h2 className="text-xl md:text-2xl lg:text-3xl font-medium mb-2 shadow-lg py-4 px-3 border-b-8 bg-gray-100 border-yellow-400">
            Dashboard Summary
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            <Card
              icon={<VscGraph className="text-red-500" size={40} />}
              title={"Total Practices"}
              count={state?.counts?.totalPracticesCount}
            />
            <Card
              icon={<VscGraph className="text-red-500" size={40} />}
              title={"Total Departments"}
              count={state?.counts?.totalDepartmentsCount}
            />
            <Card
              icon={<VscGraph className="text-red-500" size={40} />}
              title={"Total Teams"}
              count={state?.counts?.totalTeamsCount}
            />
            <Card
              icon={<VscGraph className="text-red-500" size={40} />}
              title={"Total Tickets"}
              count={state?.counts?.totalTasksCount}
            />
          </div>

          <div className="py-2">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <HeadingCard
                component={
                  <PieCharts
                    data={convertFunction(state?.priority)}
                    title={"Total"}
                    name={"priorityId"}
                    handleChange={handleChange}
                  />
                }
                title={"Priority"}
              />
              <div className="grid grid-cols-2 gap-2">
                <HeadingCard
                  component={singleComponent(status(state?.status), "statusId")}
                  title={"Status"}
                />
                <HeadingCard
                  component={singleComponent(Category)}
                  title={"Category"}
                />
              </div>
              <HeadingCard
                component={Component(
                  Department(state?.department),
                  "departmentId"
                )}
                title={"Departments"}
              />
              <HeadingCard
                component={Component(Team(state?.team), "teamId")}
                title={"Teams"}
              />
              <div className="col-span-2">
                {" "}
                <HeadingCard
                  component={singleassigneComponent(
                    assignees(state?.assignee),
                    "assigneeId"
                  )}
                  title={"Assignees"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
