import React from "react";
import CustomButton from "../../CustomButton";
import FileUploader from "../../Fileuploader";
import CustomTextArea from "../../CustontextArea";

const ReplyMessageBox = ({ state, handleChange, onSubmit, file, setFile }) => {
  return (
<div className="pb-3 mt-3">
  <CustomTextArea
    placeholder="Type your message here..."
    value={state.text}
    onChange={handleChange}
    rows={3}
    name="text"
  />
  <div className="flex justify-between items-center mt-5">
    <FileUploader files={file} setFiles={setFile} />
    <CustomButton label="Send" onClick={onSubmit} extraClass="bg-blue-500 text-white hover:bg-blue-600" />
  </div>
</div>

  );
};

export default ReplyMessageBox;
