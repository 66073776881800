import React, { lazy, useEffect, useState } from "react";
import { Header } from "../../SearchedTickect/Header";
import { callApi } from "../../common/Apicall.js";
import { useDispatch } from "react-redux";
import { addData } from "../../Slices/DataSlice.js";
import ScreenLoader from "../../common/ScreenLoader.js";

const SearchUser = lazy(() => import("../User/SearchUser/SearchUser.jsx"));
const SearchTeam = lazy(() => import("../Team/SearchTeam/SearchTeam.jsx"));
const SearchPractice = lazy(() =>
  import("../Practice/SearchPractice/SearchPractice.jsx")
);
const SearchDepartment = lazy(() =>
  import("../Department/SearchDepartment/SearchDepartment.jsx")
);
const URL = process.env.REACT_APP_URL_DOCUHUB_URL;
const tabs = [
  { id: 1, name: "Practice", link: "SearchPractice" },
  { id: 2, name: "User", link: "SearchUser" },
  { id: 3, name: "Department", link: "SearchDepartment" },
  { id: 4, name: "Teams", link: "SearchTeam" },
];

const components = {
  SearchPractice,
  SearchUser,
  SearchDepartment,
  SearchTeam,
};

const Main = () => {
  const [loader, setLoader] = useState(false);
  const [activeComponent, setActiveComponent] = useState("SearchPractice");
  const DisplayComponent = components[activeComponent];
  const dispatch = useDispatch();
  useEffect(()=>{    
      getData ()
  },[DisplayComponent])
  const getData = () => {
    
    callApi(`${URL}/Department/GetDepartmentDropDown`, "get")
      .then((res) => {
   
        dispatch(addData({ name: "practice", data: res.sideBarOptions }));
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.error("Error saving data:", error);
      });
  };
  return (
    <div >
       {loader && <ScreenLoader />}
      <Header />
      <div className="px-5 flex py-0 shadow-md justify-center border-[#f36d00] border-b-[1.5px] items-center pt-1 font-sans bg-sideNavbg">
        <div className="flex">
          {tabs.map((tab) => (
            <div
              key={tab.id}
              onClick={() => setActiveComponent(tab.link)}
              className={`${
                tab.link === activeComponent ? "bg-buttonBg" : "bg-slate-400"
              } hover:bg-buttonBg ml-1 px-2 w-[200px] cursor-pointer rounded-t-3xl 
              text-center font-semibold text-white`}
            >
              {tab.name}
            </div>
          ))}
        </div>
      </div>
      <React.Suspense fallback={<div>Loading...</div>}>
        <DisplayComponent />
      </React.Suspense>
    </div>
  );
};

export default Main;
