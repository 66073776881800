import React, { useState, useRef, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import CustomSelect from "./CustomSelect";
import CustomInput from "./CustomInput";
import { FaCircleChevronDown } from "react-icons/fa6";

const SelectWithSearch = ({
  label,
  options,
  onChange,
  extraClass,
  icon = false,
  optionExtraclass,
  value,
  ...props
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = (e) => {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      setShowOptions(false);
    }
  };

  const handleToggleClick = () => {
    setSearchTerm("");
    setShowInput((prevShowInput) => !prevShowInput);
  };

  const handleOptionClick = (option) => {
    setSearchTerm("");

    setShowOptions(false);
    setShowInput(false);
    onChange({ target: { value: option.value, name: props.name } });
  };
  const handleOptionselect = (e) => {
    onChange(e);
  };
  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    setShowOptions(true);
  };

  const filteredOptions =
    options?.length &&
    options?.filter((option) =>
      option?.label?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    );

  return (
    <div className="flex flex-col">
      <label className="font-sans text-sm mb-1">{label}</label>
      <div ref={containerRef} className="relative flex w-full">
        {showInput ? (
          <div className="w-full">
            <CustomInput
              {...props}
              type="text"
              placeholder="Search Here..."
              value={searchTerm}
              onChange={handleInputChange}
              onClick={() => setShowOptions(true)}
              extraClass={extraClass}
            />
            {showOptions && (
              <div className="absolute left-0 w-full max-h-[130px] z-10
               bg-white border border-gray-300 overflow-y-auto">
                {filteredOptions.length > 0 ? (
                  filteredOptions?.map((option, index) => (
                    <div
                      key={index}
                      onClick={() => handleOptionClick(option)}
                      className="px-3 text-[0.9rem] cursor-pointer hover:bg-blue-600 hover:text-white"
                    >
                      {option.label}
                    </div>
                  ))
                ) : (
                  <div className="text-center text-xs py-1" key={'none'}>
                    No Search found
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <CustomSelect
            {...props}
            value={value}
            onChange={(e) => handleOptionselect(e)}
            extraClass={extraClass}
          >
            <option
              value=""
              className={`${optionExtraclass} px-3 text-[0.9rem] cursor-pointer
              hover:bg-blue-600 text-black hover:text-white `}
            >
              Select an option
            </option>
            {options?.length &&
              options?.map((option) => (
                <>
                  {
                  (option.label&&option.value)&&
                  <option
                    key={option.value}
                    value={option.value}
                    className={`${optionExtraclass} px-3 text-[0.9rem] cursor-pointer
                 hover:bg-blue-600 text-black hover:text-white `}
                  >
                    {option.label}
                  </option>}
                </>
              ))}
          </CustomSelect>
        )}

        {icon ? (
          <div className="absolute right-1 bottom-2 pointer-events-none cursor-pointer bg-sideNavbg ">
            <FaCircleChevronDown
              onClick={handleToggleClick}
              className="cursor-pointer text-white text-xl  pointer-events-none"
            />{" "}
          </div>
        ) : (
          <div className="absolute right-1 cursor-pointer top-1 ">
            {" "}
            <FaSearch
              onClick={handleToggleClick}
              className={`cursor-pointer bg-white  ${
                showInput ? "text-slate-500" : ""
              }`}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectWithSearch;
