import React from "react";

const CustomSelect = ({
  children,
  extraClass,
  label,
  onLabelClick,
  value,
  ...props
}) => {
  const selectClasses =
    "border rounded w-full  px-[5.8px] py-[3px] h-7  focus:outline-none focus:shadow-outline";

  return (
    <div className="flex flex-col w-full">
      {label && (
        <label
          onClick={onLabelClick}
          htmlFor={label}
          className={`${
            props.required && "required"
          } font-semibold mb-1 w-fit  ${
            onLabelClick && "cursor-pointer text-primaryColor hover:underline"
          }`}
        >
          {label}
        </label>
      )}
      <select
  
        {...props}
        value={value}
        className={`${selectClasses} ${extraClass} `}
      >
        {children}
      </select>
    </div>
  
  );
};

export default CustomSelect;
