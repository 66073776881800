import React from "react";

export const Card = ({ icon,title ,count}) => {
  return (
    <div className=" bg-white p-3 shadow-md  transition-all duration-300 hover:shadow-2xl flex justify-between items-center">
      <div>
        <h3 className="text-sm font-medium ">{title}</h3>
        <p className="text-3xl py-3">{count}</p>
      </div>
      <div>{icon}</div>
    </div>
  );
};
