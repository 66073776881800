import React, { useEffect, useState } from "react";

import Index from "../Header/Index";
import TicketForm from "../ticketForm/TicketForm";
import Sidebar from "../sidebar/Sidebar";
import { callApi } from "../common/Apicall";
import ScreenLoader from "../common/ScreenLoader";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import Validator, {
  ValidationTypes as V_Type,
} from "react-form-supervalidator";
import uploadFile from "../common/fileApiFunction";
const CreateTickect = ({ isEdit, modalRow, onClose }) => {
  const URL = process.env.REACT_APP_URL;
  const {
    data: [element],
  } = useSelector((state) => state.data.userInfo.data);
  const [loader, setLoader] = useState(false);
  const [file, setFile] = useState([]);
  const object = {
    number: 0,
    subject: "",
    description: "",
    statusId: "1",
    priorityId: "",
    userID: element.Id,
    practiceID: "",
    departmentID: "",
    teamID: "",
    assigneeID: "",
    createdDate: null,
    updatedDate: null,
    categoryId: "",
    isActive: true,
    internalAssigneeID: 0,
    id: 0,
    errorMessage: "",
    newID: 0,
    targetDate: "",
    InternalFiles: [],
    internalComments: [],
  };

  const [state, setState] = useState(object);

  const [validationModel, setValidationModel] = useState({
    number: 0,
    subject: "",
    description: "",
    statusId: "",
    priorityId: "",
    practiceID: "",
    departmentID: "",
    teamID: "",
    categoryId: "",
    assigneeID: "",
    targetDate: "",
  });
  console.log(state);
  const setValidation = () => {
    let myvalidation_Obj = {
      ...validationModel,
      number: Validator(
        state.number,
        [V_Type.required],
        ["Number is required"]
      ),
      subject: Validator(
        state.subject,
        [V_Type.required],
        ["Subject is required"]
      ),
      description: Validator(
        state.description,
        [V_Type.required],
        ["Description is required"]
      ),
      statusId: Validator(
        state.statusId,
        [V_Type.required],
        ["Status is required"]
      ),
      priorityId: Validator(
        state.priorityId,
        [V_Type.required],
        ["Priority is required"]
      ),
      userID: Validator(state.userID, [V_Type.required], ["User is required"]),
      practiceID: Validator(
        state.practiceID,
        [V_Type.required],
        ["Practice is required"]
      ),
      departmentID: Validator(
        state.departmentID,
        [V_Type.required],
        ["Department is required"]
      ),
      teamID: Validator(state.teamID, [V_Type.required], ["Team is required"]),
      assigneeID: Validator(
        state.assigneeID,
        [V_Type.required],
        ["Assignee is required"]
      ),
      categoryId: Validator(
        state.categoryId,
        [V_Type.required],
        ["Category is required"]
      ),
    };
    setValidationModel(myvalidation_Obj);
    return Validator(myvalidation_Obj, V_Type.NullCheck);
  };

  const saveData = () => {
    if (setValidation()) {
      toast.error("Please Select all");
      return;
    }

    callApi(`${URL}/InternalTask/SaveTasks`, "post", state)
      .then((response) => {
        if (file.length > 0) {
          uploadFile({ ...file, InternalTaskId: response.id, CommentId: 0 });
        }
        setLoader(false);
        toast.success("New task created");
        onClose();
      })
      .catch((error) => {
        toast.error("Error saving data ");
      });
  };

  return (
    <div className="flex  ">
      {loader && <ScreenLoader />}

      <div className="w-[310px]  bg-sideNavbg">
        <Sidebar
          state={state}
          isEdit={isEdit}
          setState={setState}
          validationModel={validationModel}
        />
      </div>

      <div className="w-full">
        {/* Header */}
        {/* <Index k="k"   setState={setState}  state={state}/> */}

        {/* Home Page Content */}
        <div className="px-4 pt-2">
          <TicketForm
            validationModel={validationModel}
            state={state}
            setState={setState}
            saveData={saveData}
            setFile={setFile}
            file={file}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateTickect;
