import React, { useEffect, useRef, useState } from "react";
import profileImage from "../../assets/Images/profile-picture.png";
import { useDispatch, useSelector } from "react-redux";
import { setAuth } from "../Slices/AuthSlice";
import { addData } from "../Slices/DataSlice";
import { SettingsButton } from "../SearchedTickect/buttonComponenets";
import { Activescreen } from "../Slices/Activescreen";
import { IoHomeOutline } from "react-icons/io5";
import { AiOutlineLogout } from "react-icons/ai";
import { RiNotification2Line } from "react-icons/ri";
import { FaRegMessage, FaBars } from "react-icons/fa6";
import { initiateSignalR } from "../SingalR";
import { Rights } from "../common/Rightsobject";

const Index = ({ k, setTask }) => {
  const {
    data: [element],
  } = useSelector((state) => state.data.userInfo.data);
  console.log(Rights?.some((i)=>i==element.Email ))
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [notification, setNotification] = useState([]);
  const [activeTab, setActiveTab] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false); // Control for dropdown

  const handleLogout = () => {
    dispatch(setAuth(false));
    dispatch(addData({ name: "practice", data: [] }));
    dispatch(addData({ name: "userInfo", data: [] }));
    dispatch(Activescreen("Dashboard"));
  };

  const setting = () => {
    dispatch(Activescreen("Main"));
  };

  const dashboard = () => {
    dispatch(Activescreen("Dashboard"));
  };

  const settings = () => {
    dispatch(Activescreen("SearchedTickect"));
  };

  useEffect(() => {
    intiateSignalRFunc();
  }, [notification]);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setActiveTab(false);
        // setDropdownOpen(false); // Close dropdown on outside click
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const intiateSignalRFunc = async () => {
    const connect = await initiateSignalR();

    connect.on("InternalChating", (data) => {
      if (element.Id !== data.userId) {
        const newItem = {
          Dated: data.dated,
          Email: data.email,
          Id: data.id,
          Text: data.text,
          CreatedDate: data.createdDate,
          UserID: data.userID,
          TaskId: data.taskId,
        };
        setNotification((prevNotifications) => {
          const updatedNotifications = [...prevNotifications, newItem];
          return updatedNotifications.filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.Id === item.Id)
          );
        });
      }
    });
  };

  const NotificationComponent = () => (
    <div
      className="absolute top-0 right-[-18px] flex flex-col text-gray-700
       bg-white shadow-md border-2 rounded-xl"
    >
      <nav className="flex min-w-[240px] max-h-[220px] flex-col gap-1 p-2 overflow-y-auto">
        {notification.length === 0 ? (
          <div className="flex items-center justify-center">No Comments</div>
        ) : (
          <>
            <div className="flex items-center justify-center text-green-500 font-semibold">
              New Comments *
            </div>
            {notification.map((task, index) => (
              <div
                key={index}
                role="button"
                onClick={() => {
                  setTask({ ...task, taskno: task.TaskId });
                  setNotification((prev) =>
                    prev.filter((i) => task.Id !== i.Id)
                  );
                  setActiveTab(false);
                }}
                className="flex items-center w-full px-4 py-2 leading-tight transition-all
                         rounded-lg bg-gray-100 text-start text-gray-700
                         hover:bg-gray-200 hover:text-black border-b
                         focus:bg-blue-900 focus:text-white active:bg-[#2f5496] active:text-white"
              >
                <span className="font-bold">{index + 1} :</span>
                <span className="ml-3">{task.Text}</span>
              </div>
            ))}
          </>
        )}
      </nav>
    </div>
  );

  return (
    <div
      className={`flex w-full ${
        k
          ? "h-7vh justify-center"
          : "h-7vh bg-gradient-to-r from-[#a0a7af]  to-[#ced4b4] border-b-[1.5px] py-1 border-[#f36d00]"
      }`}
    >
      {!k && (
        <div className="flex items-center py-1 pl-2">
          <img
            className="h-10 rounded-full"
            src={profileImage}
            alt="profile_Image"
          />
          <div className="flex flex-col ml-2">
            <span className="text-sm md:text-md font-medium">
              <span className="font-bold">Welcome </span>
              <span className="font-bold text-blue-900 text-md md:text-xl">
                {element.FirstName} {element.LastName}
              </span>
            </span>
            <span className="text-xs md:text-sm">{element.Email}</span>
          </div>
        </div>
      )}

      {/* Dropdown Button for Small Screens */}
      <div className="flex items-center sm:hidden relative ml-auto">
        <button
          onClick={() => setDropdownOpen(!dropdownOpen)}
          className="flex items-center text-md px-3 py-1 font-medium bg-white rounded-lg text-gray-700"
        >
          <FaBars className="text-xl text-green-500" />
        </button>
        {dropdownOpen && (
          <div
            className="absolute right-0 top-10 mt-2 w-48 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 z-50"
          
          >
            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              <button
                className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                onClick={dashboard}
              >
                Dashboard
              </button>
              <button
                className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                onClick={settings}
              >
                Tickets
              </button>
              { (Rights?.some((i)=>i===element.Email ))&& (
                <SettingsButton toggleModal={setting} className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100" />
              )}
              <button
                className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Regular Buttons for Medium Screens and Above */}
      <div className="hidden sm:flex items-center m-auto mr-5 relative">
        <button
          type="button"
          className="flex items-center text-md px-3 me-2 mb-1 mt-1 py-1 font-medium bg-white rounded-lg text-gray-700"
          onClick={dashboard}
        >
          <span className="me-3">Dashboard</span>
          <IoHomeOutline className="text-xl text-green-500" />
        </button>
        <button
          type="button"
          className="flex items-center text-md px-3 me-2 mb-1 mt-1 py-1 font-medium bg-white rounded-lg text-gray-700"
          onClick={settings}
        >
          <span className="me-3">Tickets</span>
          <FaRegMessage className="text-xl text-green-500" />
        </button>

        {(Rights?.some((i)=>i===element.Email )) && (
          <SettingsButton toggleModal={setting} />
        )}

        <div
          className="px-5 cursor-pointer"
          ref={ref}
          onClick={() => setActiveTab(true)}
        >
          <RiNotification2Line
            size={24}
            className="text-xl font-semibold text-green-500"
          />
          <span className="absolute top-0 right-10 bg-green-500 w-5 rounded-full text-xs font-semibold flex items-center justify-center h-5 text-white">
            {notification.length}
          </span>
          {notification.length > 0 && (
            <span className="animate-ping-slow absolute inline-flex h-5 w-5 top-0 right-10 rounded-full bg-green-500 opacity-75"></span>
          )}
          {activeTab && (
            <div className="absolute z-10 ease-in-out duration-1000 mt-1">
              <NotificationComponent />
            </div>
          )}
        </div>

        <button
          type="button"
          className="flex items-center pl-3 hover:text-sideNavbg py-1 font-medium text-gray-500"
          onClick={handleLogout}
        >
          <AiOutlineLogout size={24} className="text-xl font-semibold text-gray-500" />
        </button>
      </div>
    </div>
  );
};

export default Index;
