import React, { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import Validator, { ValidationTypes as V_Type } from "react-form-supervalidator";
import Logo from "../../assets/Images/Group 173.png";
import textLogo from "../../assets/Images/Group 179.png";
import ScreenLoader from "../common/ScreenLoader";
import { TextPage } from "./TextPage";
import { setAuth } from "../Slices/AuthSlice";
import { addData } from "../Slices/DataSlice";
import { IoMdCall } from "react-icons/io";
import toast from "react-hot-toast";
import { HiOutlineMailOpen } from "react-icons/hi";

const URL = process.env.REACT_APP_URL_DOCUHUB_URL;

const Login = () => {
  const [loader, setLoader] = useState(false);
  const [loginModel, setLoginModel] = useState({
    email: "",
    password: "",
  });
  const [validationModel, setValidationModel] = useState({
    email: "",
    password: "",
  });

  const dispatch = useDispatch();

  const handleLogin = () => {
    if (!setValidation()) {
      setLoader(true);

      axios
        .post(`${URL}/Users/Login`, loginModel)
        .then((res) => {
          setLoader(false);
          dispatch(setAuth(true));
          dispatch(addData({ name: "userInfo", data: res }));
          toast.success("Login successfully");
        })
        .catch((response) => {
          setLoader(false);
          toast.error(response?.data);
        }).finally(()=>{
            setLoader(false);
        })
    }
  };

  const setValidation = () => {
    const myValidationObj = {
      ...validationModel,
      email: Validator(
        loginModel.email,
        [V_Type.required],
        ["Email is required"]
      ),
      password: Validator(
        loginModel.password,
        [V_Type.required],
        ["Password is required"]
      ),
    };
    setValidationModel(myValidationObj);
    return Validator(myValidationObj, V_Type.NullCheck);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginModel({ ...loginModel, [name]: value });
  };

  return (
    <>
      {loader && <ScreenLoader />}
      <div className="flex flex-col items-center justify-center w-full min-h-screen pt-6 bg-sideNavbg relative">
        {/* Contact Info */}
        <div className="absolute top-0 right-0 bg-taskHead rounded-bl-full px-4 py-1 flex border font-medium">
          <span className="flex items-center text-gray-700 me-6">
            <IoMdCall className="me-2 text-[#dbdfcb]" /> (206) 960-4536
          </span>
          <span className="flex items-center text-gray-700">
            <HiOutlineMailOpen className="me-2 text-[#e5e9d5]" />
            support@prosoftwaretech.com
          </span>
        </div>

        {/* Logo and Heading */}
        <div className="flex flex-col items-center text-center sm:text-left sm:items-start w-full max-w-4xl pt-4 mb-4">
          <div className="flex items-center mb-2">
            <img alt="Logo" width={50} src={Logo} className="me-3" />
            <div className="text-[20px] leading-[28px] tracking-widest font-light font-sans text-[#abc444]">
              Ticketing <span className="text-blue-400 font-bold">Management</span>
              <div>System</div>
            </div>
          </div>
          <img alt="Text Logo" className="ml-16 mt-4 w-full max-w-[700px]" src={textLogo} />
        </div>

        {/* Login Form and Info Section */}
        <div className="flex flex-col lg:flex-row items-center justify-center w-full max-w-4xl space-y-8 lg:space-y-0 lg:space-x-10">
          <div className="hidden lg:block">
            <TextPage />
          </div>

          <div className="flex flex-col text-white font-sans py-8 px-8 w-full max-w-md bg-gradient-to-r from-[#a0a7af] to-[#ced4b4] rounded-3xl shadow-lg">
            <span className="flex justify-center font-bold text-xl relative w-full mb-4">
              Welcome to
              <span className="text-sideNavbg ml-2">TMS</span>
              <div className="absolute text-[10px] right-0 bottom-[-20px] text-[#495321]">Version 1.0</div>
            </span>

            <div className="border-b border-dotted border-white mb-4"></div>

            {/* Email Input */}
            <label className="font-poppins text-sm font-medium leading-12 mb-2">
              Username
            </label>
            <input
              className="w-full p-2 px-3 rounded-lg text-sm text-black outline-none border border-[#d1d1d1] bg-[#f7f7f7]"
              type="email"
              name="email"
              onChange={handleChange}
              placeholder="Username"
              value={loginModel.email}
              autoComplete="on"
              required
            />
            {validationModel.email}

            {/* Password Input */}
            <label className="font-poppins text-sm font-medium leading-12 mt-4 mb-2">
              Password
            </label>
            <input
              className="w-full p-2 px-3 rounded-lg text-sm text-black outline-none border border-[#d1d1d1] bg-[#f7f7f7]"
              name="password"
              type="password"
              placeholder="Password"
              onChange={handleChange}
              value={loginModel.password}
              required
            />
            {validationModel.password}

            {/* Forget Password */}
            <label className="font-poppins text-sm font-medium leading-12 mt-4 mb-2 cursor-pointer">
              Forget password?
            </label>

            {/* Login Button */}
            <div className="flex items-center justify-center mt-5">
              <button
                className="py-2 px-5 bg-loginbtn shadow-2xl text-white text-lg rounded-lg"
                onClick={handleLogin}
              >
                Login
              </button>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="mt-auto text-[14px] tracking-widest font-light font-sans text-center text-white bg-slate-600/60 p-2 w-full">
          © 2024 ProSoftware Technologies,All rights reserved
        </div>
      </div>
    </>
  );
};

export default Login;
