import React, { useEffect, useRef, useState } from "react";
import { GrAttachment } from "react-icons/gr";
import ReplyMessageBox from "../MessageCard/MessageCard";
import { ConversationalCard } from "../ConverstionCard/ConverstionalCard";
import SideBarOptions from "../../DropdownValusesSidebar";
import { callApi } from "../../Apicall";
import ScreenLoader from "../../ScreenLoader";
import moment from "moment";
import { CiCalendarDate } from "react-icons/ci";
import { IoTimeOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { BiMessageRoundedDetail } from "react-icons/bi";
import { TbHistory } from "react-icons/tb";
import uploadFile from "../../fileApiFunction";
import toast from "react-hot-toast";
import { initiateSignalR } from "../../../SingalR";
import CustomModal from "../../CustomModal";
import ViewDocument from "../../fileView";

const Card = ({ modalRow, component, smoothScroll, setFile, file }) => {
  const URL = process.env.REACT_APP_URL;
  const [isConversationalCardVisible, setIsConversationalCardVisible] =
    useState(true);
  const [isAudit, setIsAudit] = useState(false);
  const { Status } = SideBarOptions();
  const [loader, setLoader] = useState(false);
  const [dataModal, setDataModal] = useState([]);
  const [viewFile, setViewfile] = useState(null);
  const {
    data: [element],
  } = useSelector((state) => state.data.userInfo.data);

  const [state, setState] = useState({
    id: 0,
    taskId: modalRow.taskno,
    text: "",
    userID: element.Id,
    email: element.Email,
    dated: "2024-03-28T16:53:43.480Z",
    createdDate: "2024-03-28T16:53:43.480Z",
  });

  const refc = useRef(null);

  useEffect(() => {
    GetbyTaskId();
  }, [modalRow.taskno]);

  useEffect(() => {
    smoothScroll();
  }, [isConversationalCardVisible, dataModal]);

  const GetbyTaskId = () => {
    setLoader(true);
    callApi(`${URL}/Comment/GetCommentsByTaskId/${modalRow.taskno}`, "get")
      .then((response) => {
        setDataModal(response);
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching comments:", error);
        setLoader(false);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const uploadFilesWithAdditionalData = async (files, taskId, commentsId) => {
    try {
      const obj = {
        files,
        InternalTaskId: taskId,
        id: 0,
        isCommentFile: true,
        path: "",
        CommentsId: commentsId ?? null,
      };
      await uploadFile(obj);
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  useEffect(() => {
    intiateSignalRFunc();
  }, []);

  const intiateSignalRFunc = async () => {
    const connect = await initiateSignalR();
    connect.on("InternalChating", (data) => {
      if (state.taskId == data.taskId) {
        const newItem = {
          Dated: data.dated,
          Email: data.email,
          Id: data.id,
          Text: data.text,
          CreatedDate: data.createdDate,
          UserID: data.userID,
          key: null,
          TaskId: data.taskId,
        };
        setDataModal((prevDataModal) => {
          const items = new Set([...prevDataModal, newItem]);
          return Array.from(items);
        });
      }
    });
  };
  const GetfilesbyKey = (key) => {
    setViewfile(key);
  };
  const onSubmit = () => {
    if (!state.text) {
      return;
    }
    setLoader(true);
    callApi(`${URL}/Comment/SaveComments`, "post", state)
      .then(async (response) => {
        toast.success("Comments added successfully");
        if (file.length > 0) {
          await uploadFilesWithAdditionalData(
            file,
            response.taskId,
            response.id
          );
        }
        setState((prevState) => ({
          ...prevState,
          text: "",
        }));
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error saving comments:", error);
        setLoader(false);
      });
  };

  const dateTime = moment(modalRow.createdDate);
  const date = dateTime.format("MM-DD-YYYY");
  const time = dateTime.format("hh:mm:ss A");

  return (
    <>
      {viewFile && (
        <CustomModal
          title={"View File"}
          onClose={() => setViewfile(null)}
          ModalBody={<ViewDocument modalRow={modalRow} />}
          size={"xxxl"}
        />
      )}
      <div
        className="bg-white rounded-lg overflow-y-auto shadow-md px-4 border border-gray-300 w-full"
        ref={refc}
      >
        {loader && <ScreenLoader />}
        <div>
          <div className="flex w-full text-center mt-1 px-4 rounded-t-md text-sm">
            <div className="flex items-center space-x-1 h-26 my-2 text-buttonBg">
              <span className="font-bold">Created Date:</span>
              <i className="text-xl">
                <CiCalendarDate />
              </i>
              <span className="border-r border-solid border-black pr-2">
                {date}
              </span>
              <i className="text-lg">
                <IoTimeOutline />
              </i>
              <span>{time}</span>
            </div>
            <div className="flex text-md justify-center ml-auto mt-2 text-sm">
              <span className="font-bold text-buttonBg">Status:</span>
              <span className="ml-2">{modalRow.Status}</span>
            </div>
          </div>
        </div>

        {/* Card Body */}
        <div className="px-5 py-3 mb-3 shadow-lg rounded-lg border-blue-600 border text-sm">
          <div className="mb-1 flex items-center space-x-2">
            <label className="font-semibold text-gray-700">Subject:</label>
            <p className="flex-1 mt-3">
              <u className="text-gray-500">
                <sup className="text-sm text-black ml-12">
                  {modalRow.subject}
                </sup>
              </u>
            </p>
          </div>
          <div className="relative">
            <label className="font-semibold text-gray-700 absolute">
              Description:
            </label>
            <p className="text-sm text-gray-800 sm:ml-0 md:ml-28 border-b-2 pb-1 border-dotted border-gray-800">
              {modalRow.description}
            </p>
            <div className="px-5 text-right">
              <div className="flex justify-end items-center text-gray-600">
                {modalRow.key && (
                  <div className="bg-buttonBg justify-center items-center mt-2 flex p-1 px-3 rounded-full text-white">
                    <div onClick={() => GetfilesbyKey(modalRow)}>
                      {" "}
                      <GrAttachment />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Tabs */}
        <div className="flex py-2 mt-1 mb-5">
          <div
            className={`mr-2 ${
              isConversationalCardVisible ? "bg-[#2f5496]" : "bg-[#914A81]"
            } rounded-md px-2 flex items-center justify-center`}
          >
            <i className="border border-solid border-white p-1 rounded-full">
              <BiMessageRoundedDetail className="text-lg text-white" />
            </i>
            <span
              onClick={() => {
                setIsConversationalCardVisible(true);
                setIsAudit(false);
              }}
              className="px-2 py-1.5 text-white"
            >
              Conversational
            </span>
          </div>

          <div
            className={`${
              isAudit ? "bg-[#2f5496] text-white" : "bg-gray-200"
            } rounded-md px-2 text-right flex items-center justify-center`}
          >
            <TbHistory className="text-lg" />
            <span
              onClick={() => {
                setIsAudit(true);
                setIsConversationalCardVisible(false);
              }}
              className="px-2 py-1.5"
            >
              History
            </span>
          </div>
        </div>

        {isConversationalCardVisible && (
          <>
            <ConversationalCard modalRow={modalRow} dataModal={dataModal} />
            <ReplyMessageBox
              modalRow={modalRow}
              handleChange={handleChange}
              state={state}
              onSubmit={onSubmit}
              file={file}
              setFile={setFile}
              dataModal={dataModal}
            />
          </>
        )}
      </div>
      <div className="flex items-end justify-end mt-2 pe-4">{component}</div>
    </>
  );
};

export default Card;
