import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { RxCross1 } from "react-icons/rx";
import ScreenLoader from "./ScreenLoader";

const modalSizes = {
  xl: "w-[800px]",
  xxl: "sm:w-[800px] md:w-[1140px]",
  xxxl: "md:w-[90%] sm:w-[800px]",
  sm: "w-[500px]",
};

const CustomModal = ({ isLoading, title, ModalBody, size, onClose }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    setPosition({ x: 0, y: 0 });
  }, [size]);

  return (
    <div
      className="min-w-full min-h-screen flex justify-center items-center fixed
        top-0 left-0 bg-gray-600/90 z-10"
    >
      {isLoading && <ScreenLoader />}
      <Draggable
        handle=".drag-handle"
        position={position}
        onStop={(e, data) => setPosition({ x: data.x, y: data.y })}
      >
        <div className={`bg-white flex flex-col ${modalSizes[size]} rounded-t-xl max-h-[94vh]`}>
          {/* Header */}
          <div className="bg-sideNavbg px-3 flex justify-between items-center rounded-t-xl drag-handle">
            <span
              className="relative text-md text-white font-semibold px-3 ml-5 before:content-[''] before:w-3 before:h-3 before:rounded-full before:absolute before:top-[5px] before:-left-2 before:bg-white"
            >
              {title}
            </span>
            <span
              onClick={onClose}
              className="cursor-pointer flex items-center justify-center text-lg text-white"
            >
              <RxCross1 />
            </span>
          </div>
          {/* Body */}
          <div className="bg-white overflow-auto">{ModalBody}</div>
        </div>
      </Draggable>
    </div>
  );
};

export default CustomModal;
