import { callApi } from "./Apicall";

const uploadFile = (file) => {
    const URL = process.env.REACT_APP_URL;
    return new Promise((resolve, reject) => {
        callApi(`${URL}/File/UploadsFiles`, 'post', file, true)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export default uploadFile;

