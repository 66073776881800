import React, { useEffect, useState } from "react";
import SelectWithSearch from "../common/commonSelect";
import CustomInput from "../common/CustomInput";
import SideBarOptions from "../common/DropdownValusesSidebar";
import { useSelector } from "react-redux";
import { callApi } from "../common/Apicall";
import ScreenLoader from "../common/ScreenLoader";

const Sidebar = ({ state, setState, isEdit, validationModel }) => {
  const {
    data: [element],
  } = useSelector((state) => state.data.userInfo.data);

  let sideBarOption = { ...SideBarOptions() };
  const [sideBarOptions, setSideBarOptions] = useState(sideBarOption);
  const [loader, setLoader] = useState(false);
  const URL = process.env.REACT_APP_URL;

  const handleChange = (e) => {
    const { value, name } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  console.log(state);
  useEffect(() => {
    if (state.departmentID) {
      getTeamDropDown(state.departmentID);
    } else {
      valueChange([{ Icon: "" }], "Team");
    }
  }, [state.departmentID]);

  useEffect(() => {
    if (state.teamID && state.departmentID) {
      getUserDropDown(state.departmentID, state.teamID);
    } else {
      valueChange([{ Icon: "" }], "Assignee");
    }
  }, [state.teamID, state.departmentID]);

  const getTeamDropDown = (id) => {
    setLoader(true);
    callApi(`${URL}/Department/GetDepartmentDropDownbyId?deptid=${id}`, "get")
      .then((response) => {
        const arr =
          response.length > 0
            ? response.map((i) => ({ label: i.TeamName, value: i.Id }))
            : [{ Icon: "" }];
        valueChange(arr, "Team");
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error Getting data:", error);
        setLoader(false);
      });
  };

  const getUserDropDown = (depid, teamid) => {
    setLoader(true);
    callApi(
      `${URL}/Users/GetUserDropDownById?departmentId=${depid}&teamid=${teamid}`,
      "get"
    )
      .then((response) => {
        const arr = response.length > 0 ? [...response] : [{ Icon: "" }];
        valueChange(arr, "Assignee");
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error Getting data:", error);
        setLoader(false);
      });
  };

  const valueChange = (items, label) => {
    setSideBarOptions((prevOptions) => {
      const updatedOptions = {
        ...prevOptions,
        [label]: {
          ...prevOptions[label],
          value: (() => {
            const values = [...items];
            if (values.length > 0) {
              const updatedValues = [...values];
              updatedValues[0] = {
                ...updatedValues[0],
                Icon: prevOptions[label]?.value?.[0]?.Icon || "",
              };
              return updatedValues;
            }
            return [];
          })(),
        },
      };
      return updatedOptions;
    });
  };

  const name = `${element?.FirstName || ""} ${element?.LastName || ""}`;
console.log(validationModel)
  return (
    <>
      {loader && <ScreenLoader />}
      <div className="w-full bg-sideNavbg border border-slate-700">
        <div className="bg-gradient-to-r from-[#a0a7af] to-[#ced4b4] flex justify-center">
          <span className="text-lg px-3 text-white font-semibold">
            Task # {state.id}
          </span>
        </div>
        <div className="py-2 border-b text-sm border-gray-400">
          <span className="text-white text-sm pl-5">Showed Results By</span>
        </div>
        <ul>
          {Object.keys(sideBarOptions).map((sidebarTitle) => (
            <li
              className="border-b py-2 text-sm flex border-gray-400"
              key={sidebarTitle}
            >
              <div className="flex text-sm items-center">
                <div className="rounded-full flex w-8 ml-2 justify-center items-center">
                  <i className="text-white text-2xl">
                    {sideBarOptions[sidebarTitle]?.value[0]?.Icon}
                  </i>
                </div>
              </div>
              <div className="w-full flex flex-col pl-2">
                <div>
                  <span className="pl-2 text-white">{sidebarTitle}</span>
                </div>
                <div className="pe-1 text-blue-700">
                  {!(
                    sidebarTitle === "Target Date" || sidebarTitle === "Status"
                  ) &&
                  (sidebarTitle === "Reporter" || isEdit) &&
                  (sidebarTitle === "Reporter" ||
                    sidebarTitle === "Practice" ||
                    sidebarTitle === "Source") ? (
                    <span className="text-blue-400 block text-sm">
                      {sidebarTitle === "Source" ||
                      sidebarTitle === "Target Date" ||
                      sidebarTitle === "Reporter" ? (
                        sidebarTitle === "Target Date" ? (
                          <div className="pl-1">
                            {state[sideBarOptions[sidebarTitle].name]?.split(
                              "T"
                            )[0] || <span>No {sidebarTitle} Selected</span>}
                          </div>
                        ) : sidebarTitle === "Reporter" ? (
                          <span className="pl-2">
                            {state?.id == 0 ? name : state?.Reporter}
                          </span>
                        ) : (
                          <div className="pl-2">
                            {state[sideBarOptions[sidebarTitle].name] || (
                              <span>
                                No {sideBarOptions[sidebarTitle].name} Selected
                              </span>
                            )}
                          </div>
                        )
                      ) : (
                        <div className="pl-2">
                          {sideBarOptions[sidebarTitle].value?.find(
                            (item) =>
                              item.value ===
                              state[sideBarOptions[sidebarTitle].name]
                          )?.label || <span>No {sidebarTitle} Selected</span>}
                        </div>
                      )}
                    </span>
                  ) : (sidebarTitle === "Source" ||
                      sidebarTitle === "Target Date") &&
                    sideBarOptions[sidebarTitle].value.find(
                      (option) => option.input
                    ) ? (
                    <>
                      <CustomInput
                        type={sidebarTitle === "Target Date" ? "date" : "text"}
                        placeholder={
                          sidebarTitle === "Target Date"
                            ? "Enter target date"
                            : "Enter source"
                        }
                        extraClass={`text-sm h-[25px] bg-sideNavbg border-sideNavbg ${sideBarOptions[sidebarTitle].color}`}
                        name={
                          sidebarTitle === "Target Date"
                            ? "targetDate"
                            : "source"
                        }
                        value={
                          sidebarTitle === "Target Date"
                            ? state?.targetDate?.split("T")[0]
                            : state.source
                        }
                        onChange={handleChange}
                      />
                      {validationModel && validationModel?.targetDate}
                    </>
                  ) : (
                    <>
                      <SelectWithSearch
                        icon={true}
                        extraClass={`${sideBarOptions[sidebarTitle].color} font-sans bg-sideNavbg border-0 mt-[-7px] pt-0`}
                        options={sideBarOptions[sidebarTitle].value}
                        onChange={handleChange}
                        name={sideBarOptions[sidebarTitle].name}
                        value={state[sideBarOptions[sidebarTitle].name]}
                        optionExtraclass="text-white"
                      />
                      {validationModel &&
                        validationModel[sideBarOptions[sidebarTitle]?.name]}
                    </>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
