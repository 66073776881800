import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";


ChartJS.register(ArcElement, Tooltip, Legend);

export function PieCharts({ data, title, handleChange, name }) {
  const handleLegendClick = (event, legendItem) => {
    const index = legendItem[0].index;
    const clickedData = data[index];
    handleChange(name, clickedData.id);
  };
  const colorsObj = [
    "rgba(34, 197, 94, 0.6)",  // Low - Green
    "rgba(37, 99, 235, 0.6)",  // Normal - Blue
    "rgba(249, 168, 37, 0.6)",  // High - Yellow
    "rgba(239, 68, 68, 0.6)",   // Urgent - Red
  ];
  const sortedData = data.sort((a, b) => {
    const priorityOrder = ["Low", "Normal", "High", "Urgent"];
    return priorityOrder.indexOf(a.label) - priorityOrder.indexOf(b.label);
  });
  const options = {
    layout: {
      padding: {
        top: 20,
        bottom: 45,
        left: 10,
        right: 15,
      },
    },
    plugins: {
      title: {
        display: true,
        text: title,
        font: {
          size: 18,
          weight: "bold",
          family: "Arial",
        },
        padding: {
          top: 15,
          bottom: 15,
        },
      },
      legend: {
        position: "left",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          padding: 25,
          color: "rgba(0, 0, 0, 0.87)",
          font: {
            size: 14,
            family: "Arial",
            weight: "normal",
          },
        },
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    onClick: handleLegendClick,
    onHover: (event, elements) => {
      // Check if the hover event is over a legend label or chart segment
      const target = event.native ? event.native.target : null;
      if (elements.length) {
        target.style.cursor = "pointer"; // Set the cursor to pointer if hovering over an element
      } else {
        target.style.cursor = "default"; // Reset cursor to default when not hovering
      }
    },
  };

  const dataSet = {
    labels: sortedData.map((d) => d.label),
    datasets: [
      {
        data: sortedData.map((d) => d.totalCount),
        backgroundColor: colorsObj,
        borderColor: colorsObj,
        borderWidth: 1,
      },
    ],
  };

  return <Pie data={dataSet} options={options} />;
}
