import React, { useEffect, useRef, useState } from "react";
import { CreateTaskButton, MyTaskButton } from "./buttonComponenets";
import { Header } from "./Header";
import SearchFilters from "./SearchFilters";
import { callApi } from "../common/Apicall";
import ScreenLoader from "../common/ScreenLoader";
import SideBarOptions from "../common/DropdownValusesSidebar";
import AllModals from "./AllModals";
import Taskindex from "./TaskIndex";
import DeleteAlert from "../common/DeleteAlert";
import { MdDelete } from "react-icons/md";
import { useSelector } from "react-redux";
import { Rights } from "../common/Rightsobject";

const SearchedTickect = ({ dashboardstate, setdashboardstate }) => {
  const { Practice, Priority, Team, Status, Assignee, Department,Category } =
    SideBarOptions();
  const URL = process.env.REACT_APP_URL;
  const containerRef = useRef(null);

  const [isEdit, setIsEdit] = useState(false);
  const {
    data: [element],
  } = useSelector((state) => state.data.userInfo.data);
  const initialImportModel = {
    perPage: 10,
    pageNo: 1,
    practiceId: null,
    totalPages: 0,
    totalCount: 0,
    departmentId: null,
    createdFrom:'',
    createdTo:'',
    teamId: null,
    totalRows: "",
    id: '',
    priorityId: "",
    categoryId:'',
    name:'',
    statusId: "",
  };
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [state, setState] = useState(dashboardstate);

  const [isModalOpen, setIsModalOpen] = useState({
    isModalOpenCreateTask: false,
    isModalOpenMyTaskList: false,
    isConversationOpen: false,
  });

  const [loader, setLoader] = useState(false);
  const [activeTab, setActiveTab] = useState(false);

  const toggleModal = (modalName) => {
    setIsModalOpen((prevState) => ({
      ...prevState,
      [modalName]: !prevState[modalName],
    }));
  };
console.log(dashboardstate)
  const toggleActive = (check) => {
    setActiveTab(check);
  };

  useEffect(() => { 
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
      setdashboardstate.setState((prevState) => ({
        ...prevState,
        importModel: initialImportModel,
      }));
    };
  }, []);

  const handleOutsideClick = (e) => {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      setActiveTab(false);
    }
  };

  const onClose = (header) => {
    setIsEdit(false);
    getData(state.importModel);
    setState({ ...state, modalRow: null });
    setIsModalOpen((prevState) => ({
      ...prevState,
      [header]: !prevState[header],
    }));
  };

  const onClear = () => {
    setState((prev) => ({
      ...prev,
      importModel: { ...initialImportModel },
    }));
    getData(initialImportModel);
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
  };

  const onSearch = () => {
    const payload = {
      ...state.importModel,
      perPage: 10,
      pageNo: 1,
    };

    setState((prevState) => ({
      ...prevState,
      importModel: {
        ...prevState.importModel,
        pageSize: 10,
        pageNumber: 1,
      },
    }));
    getData(payload);
  };

  const getData = (payload) => {
    setLoader(true);
    callApi(`${URL}/InternalTask/GetTasksByModel`, "post", payload)
      .then((res) => {
        setState((prevState) => ({
          ...prevState,
          rows: res.data,
          totalRows: res.totalCount,
          isLoading: false,
          addPopup: "",
        }));
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    getData(state.importModel);
   
  }, [state.importModel.perPage, state.importModel.pageNo]);

  const handlePerRowsChange = async (pageNo, perPage) => {
    await setState({
      ...state,
      importModel: {
        ...state.importModel,
        pageNo,
        perPage,
      },
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      importModel: {
        ...state.importModel,
        [name]: value,
      },
    });
  };

  const handleEdit = (header, modalRow) => {
    if (header === "isModalOpenMyTaskList") {
      setIsEdit(true);
    }
    setState({ ...state, modalRow });
    setIsModalOpen((prevState) => ({
      ...prevState,
      [header]: !prevState[header],
    }));
  };
  const priorities = [
    { value: 1, label: "Low", colorClass: "text-green-500" },
    { value: 2, label: "Normal", colorClass: "text-blue-500" },
    { value: 3, label: "High", colorClass: "text-yellow-500" },
    { value: 4, label: "Urgent", colorClass: "text-red-500" },
  ];
  const columns = [
    {
      header: "Task No",
      cell: ({ row: { original } }) => {
        return (
          <a
            href="/#"
            className="text-blue-700 cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              handleEdit("isModalOpenMyTaskList", original);
            }}
          >
            {original.taskno}
          </a>
        );
      },
    },
    {
      header: "Practice Name",
      size: 250,
      accessorKey: "practicename",
    },
    {
      header: "Subject",
      accessorKey: "subject",
      size: 250,
    },
    {
      header: "Department",
      accessorKey: "Department",
    },
    {
      header: "Team",
      accessorKey: "teamname",
    },
    {
      header: "Assignee",
      accessorKey: "Assignee",
    },
    {
      header: "Status",
      accessorKey: "Status",
    },
    {
      header: "Priority",
      cell: ({ row: { original } }) => {
        return (
          <span
            className={`font-semibold ${
              priorities.find((i) => i.label === original?.Priority)?.colorClass
            }`}
          >
            {original?.Priority}
          </span>
        );
      },
      sortable: true,
      resizable: true,
    },
    {
      header: "Created Date",
      cell: ({ row: { original } }) => {
        return <span>{original?.CreatedDate?.split("T")[0]}</span>;
      },
      sortable: true,
      resizable: true,
    },
  ];

  if (Rights?.some((i)=>i==element.Email )) {
    columns.push({
      header: "Action",
      size: 50,
      cell: ({ row: { original } }) => {
        return (
          <div className="flex items-center justify-center h-full space-x-3">
            <span
              onClick={async () => {
                await DeleteAlert(
                  `${URL}/InternalTask/DeleteTasks/${original?.taskno}`
                );
                onClear();
              }}
              className="flex items-center justify-center"
            >
              <MdDelete className="w-4 h-4 cursor-pointer text-red-500" />
            </span>
          </div>
        );
      },
    });
  }

  const setTask = (data) => {
    handleEdit("isModalOpenMyTaskList", data);
  };

  return (
    <>
      {loader && <ScreenLoader />}
      <Header setTask={setTask} />

      <div className="flex px-5 py-0 shadow-md items-center justify-between border-b-2 font-sans bg-sideNavbg">
        <MyTaskButton
          activeTab={activeTab}
          toggleActive={toggleActive}
          containerRef={containerRef}
          setTask={setTask}
        />
        <div className="flex">
          <CreateTaskButton toggleModal={toggleModal} />
        </div>
      </div>

      <SearchFilters
        state={state}
        handleChange={handleChange}
        toggleActive={toggleActive}
        onSearch={onSearch}
        onClear={onClear}
        Practice={Practice}
        Department={Department}
        Team={Team}
        Assign={Assignee}
        Priority={Priority}
        Status={Status}
        Category={Category}
      />

      <Taskindex
        columns={columns}
        setPagination={setPagination}
        pagination={pagination}
        state={state}
        handlePerRowsChange={handlePerRowsChange}
      />

      <AllModals
        isModalOpen={isModalOpen}
        onClose={onClose}
        state={state}
        isEdit={isEdit}
      />
    </>
  );
};

export default SearchedTickect;
