import React, { useState, useEffect } from "react";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";

const Table = ({
  data,
  columns,
  maxHeight,
  totalCount = 0,
  isShowPagination = true,
  getPaginationChangeValue,
  setPagination,
  pagination,
  exportTo = false,
}) => {
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPageRow, setPerPageRow] = useState("10");

  const [totalPages, setTotalPages] = useState([]);
 
  const [currentPageGroup, setCurrentPageGroup] = useState(0);

  useEffect(() => {
    if (isShowPagination) {
      const pageSizeVal = perPageRow ? parseInt(perPageRow) : 5;
      if (!isNaN(totalCount) && totalCount > 0 && pageSizeVal > 0) {
        const pages = Math.ceil(totalCount / pageSizeVal);

        if (pages > 0) {
          const result = Array(pages).fill(pageSizeVal);
          setTotalPages(result);
        } else {
          setTotalPages([]);
        }
      } else {
        setTotalPages([]);
      }
    }
  }, [data, perPageRow]);
  useEffect(() => {
    if (pagination && isShowPagination) {
      const activePageIndex = pagination.pageIndex;
      const activePageSize = pagination.pageSize;

      getPaginationChangeValue(
        Number(activePageIndex + 1),
        Number(activePageSize)
      );
    }
  }, [pagination?.pageIndex, pagination.pageSize]);

  const table = useReactTable({
    data: data,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    pageCount: totalPages.length,
    onPaginationChange: setPagination,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
  });

  const perPageRows = [
    { id: "10", name: "10" },
    { id: "20", name: "20" },
    { id: "30", name: "30" },
    { id: "50", name: "50" },
    { id: "100", name: "100" },
  ];

  const handlePerPageRowChange = (event) => {
    const arg = event.target.value;
    table.setPageSize(Number(arg));
    setPerPageRow(arg);
  };

  const handlePageClick = async (index) => {
    setCurrentPage(index + 1);

    await table.setPageIndex(index);
  };

  const goToNextPage = async () => {
    await table.nextPage();
    const pageIndex = table.getState().pagination.pageIndex;
    const groupIndex = Math.floor(pageIndex / 5);
    setCurrentPageGroup(groupIndex * 5);
  };

  const moveToPreviousPage = async () => {
    await table.previousPage();
    const pageIndex = table.getState().pagination.pageIndex;
    const groupIndex = Math.floor(pageIndex / 5);
    setCurrentPageGroup(groupIndex * 5);
  };

  const goToNextPageGroup = () => {
    setCurrentPageGroup((prevGroup) => prevGroup + 5); 
  };

  const goToPreviousPageGroup = () => {
    setCurrentPageGroup((prevGroup) => Math.max(0, prevGroup - 5));
  };

  const renderPagination = () => {
    const pageCount = totalPages.length;
    const currentPage = table.getState().pagination.pageIndex;
    const pageNumbers = [];

    if (pageCount <= 5) {
      for (let i = 0; i < pageCount; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(0);

      const startPage = Math.max(1, currentPage - 1);
      const endPage = Math.min(pageCount - 2, currentPage + 1);

      if (currentPage > 2) {
        pageNumbers.push("...");
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      if (endPage < pageCount - 2) {
        pageNumbers.push("...");
      }

      pageNumbers.push(pageCount - 1);
    }

    const uniquePageNumbers = Array.from(new Set(pageNumbers));

    return (
      <div className="flex items-center gap-2">
        {uniquePageNumbers.map((page, index) => {
          if (page === "...") {
            return (
              <span key={index} className="w-6 h-6 text-center">
                {page}
              </span>
            );
          }
          return (
            <button
              key={page}
              onClick={() => handlePageClick(page)}
              className={`w-8 h-6  text-xs  rounded-md ${
                page === currentPage
                  ? "text-white border-color1  bg-sideNavbg border"
                  : "text-gray-800 bg-slate-300  border-[#7A8C99] hover:bg-sideNavbg hover:text-white"
              }`}
            >
              {Number(page) + 1}
            </button>
          );
        })}
      </div>
    );
  };

  const capitalizeFirstChar = (str) => {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const renderCell = (cell) => {
    const value = cell.getValue();
    const columnDef = cell.column.columnDef;
    const timestampRegex =
      /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,3}))?$/;

    if (typeof value === "string" && !timestampRegex.test(value)) {
      return capitalizeFirstChar(value)?.slice(0, 22);
    }

    if (columnDef.cell) {
      return columnDef.cell(cell);
    }

    return value;
  };

  const dummyRows = Array.from(
    { length: pagination.pageSize - data.length },
    (_, i) => ({
      id: `dummy-${i}`,
      dummy: true,
    })
  );

  return (
<div className="flex flex-col justify-between">
  <div className="border-white rounded-t-lg border-2 flex-grow">
    <div
      className={`${
        maxHeight ? maxHeight : "max-h-96"
      } w-full  transition-all duration-700 overflow-hidden rounded-t-lg border-t-[1.5px] border-[#f36d00]`}
    >
      <div className="overflow-x-auto overflow-y-scroll overflow-hidden">
        <table className="w-full rounded-t-lg">
          <thead className="sticky top-0 border-b bg-sidebar  rounded-t-lg">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers?.map((header) => (
                  <th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    style={{
                      width:
                        header.getSize() !== 150 ? header.getSize() : undefined,
                    }}
                    className="text-md px-3 py-1 text-left text-sm text-white bg-sideNavbg font-semibold cursor-pointer"
                  >
                    <div className="flex items-center justify-start w-full">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                      {header.column.getIsSorted() !== false && (
                        <span>
                          {header.column.getIsSorted() === "asc"
                            ? " ↑"
                            : " ↓"}
                        </span>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.length > 0 ? (
              <>
                {table.getRowModel().rows.map((row, rowIndex) => (
                  <tr
                    key={row.id}
                    className={`${
                      rowIndex % 2 === 0 ? "bg-[#f3f4f6]" : "bg-white"
                    } border-b transition duration-300 ease-in-out hover:bg-gray-300`}
                  >
                    {row.getVisibleCells()?.map((cell) => (
                      <td
                        key={cell.id}
                        className="px-3 py-1 text-[#596268] text-sm font-medium"
                      >
                        <div className="flex items-center justify-start text-center" key={cell.id}>
                          {renderCell(cell)}
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
                {dummyRows.map((row) => (
                  <tr key={row.id} className="">
                    {columns.map((col) => (
                      <td
                        key={col.id}
                        className="px-3 py-1 h-6 text-gray-500 text-sm"
                      ></td>
                    ))}
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td
                  colSpan={columns.length}
                  className="text-center py-4 h-64 text-rose-700"
                >
                  No data Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  </div>

  {isShowPagination && (
    <div className="flex items-center justify-end gap-4 mt-4">
      <div className="flex items-center justify-between gap-4">
        <button
          onClick={moveToPreviousPage}
          disabled={!table.getCanPreviousPage()}
          className="text-gray-800 rounded-lg bg-slate-300 px-2 flex items-center h-6 border-[#7A8C99] hover:bg-sideNavbg hover:text-white text-sm disabled:text-[#7A8C99] disabled:cursor-not-allowed"
        >
          <GrFormPrevious size={20} /> Previous
        </button>
        <button
          onClick={goToPreviousPageGroup}
          className="w-6 h-6 text-xs text-gray-800 border-[#7A8C99]"
          disabled={currentPageGroup === 0}
        ></button>
        <div>{renderPagination()}</div>
        <button
          onClick={goToNextPageGroup}
          className="w-6 h-6 text-xs text-gray-800 border-[#7A8C99]"
          disabled={currentPageGroup + 5 >= totalPages.length}
        ></button>
        <button
          disabled={!table.getCanNextPage()}
          onClick={goToNextPage}
          className="text-gray-800 rounded-lg bg-slate-300 px-2 flex items-center h-6 border-[#7A8C99] hover:bg-sideNavbg hover:text-white text-sm disabled:text-[#7A8C99] disabled:cursor-not-allowed"
        >
          Next <GrFormNext size={20} />
        </button>
        <div className="flex items-center space-x-2">
          <span className="text-sm">Items per page</span>

          <div className="w-[60px] dark:bg-slate-800 h-[38px] focus:outline-none focus:border-secondary focus:ring-1 focus:ring-secondary">
            <select
              name="perPage"
              id="perPage"
              onChange={handlePerPageRowChange}
              className="dark:bg-slate-800 h-[38px] focus:outline-none focus:border-secondary focus:ring-1 focus:ring-secondary"
            >
              {perPageRows.map((row) => (
                <option
                  key={row.id}
                  value={row.id}
                  className="appearance-auto hover:appearance-none cursor-pointer bg-white text-black dark:bg-slate-800 dark:text-white dark:border-white hover:bg-[#f7941d] hover:border-[#f7941d] dark:hover:appearance-auto dark:hover:bg-[#f7941d] dark:hover:border-[#f7941d] hover:text-white hover:dark:text-white"
                >
                  {row.name}
                </option>
              ))}
            </select>
          </div>

          <span className="text-sm">
            Page {currentPage}-{perPageRow} of {totalCount} items
          </span>
        </div>
      </div>
    </div>
  )}
</div>

  );
};

export default Table;
