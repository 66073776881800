import moment from "moment";
import React, { useRef, useEffect } from "react";
import { CiCalendarDate } from "react-icons/ci";
import { IoTimeOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import DeleteAlert from "../../../common/DeleteAlert";
import { useVirtualizer } from "@tanstack/react-virtual";
import { TiAttachmentOutline } from "react-icons/ti";
import { MdDelete } from "react-icons/md";
import { Rights } from "../../Rightsobject";
const URL = process.env.REACT_APP_URL;
export const ConversationalCard = ({ dataModal }) => {
  const {
    data: [element],
  } = useSelector((state) => state.data.userInfo.data);

  const parentRef = useRef(null);

  // Estimate size including margin
  const itemMargin = 10; // Adjust this as needed
  const rowVirtualizer = useVirtualizer({
    count: dataModal.length,
    getScrollElement: () => parentRef.current,
    estimateSize: React.useCallback(() => 140 + itemMargin, []), // Include margin in the size
    overscan: 6, // Number of items to render beyond the viewport
  });

  useEffect(() => {
    if (parentRef.current) {
      parentRef.current.scrollTop = parentRef.current.scrollHeight;
    }
  }, [dataModal]);

  return (
    <div
      ref={parentRef}
      key={"1"}
      style={{
        overflowY: "auto",
        maxHeight: "calc(76vh - 2rem)",
        position: "relative",
      }}
    >
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: "100%",
          position: "relative",
        }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualRow) => {
          const i = dataModal[virtualRow.index];
          if (!i) return null;

          const dateTime = moment(i.CreatedDate);
          const date = dateTime.format("MM-DD-YYYY");
          const time = dateTime.format("hh:mm:ss A");

          return (
            <div
              key={i.id}
              className={`${
                i.Email === element.Email ? "bg-[#f3ede9]" : "bg-[#e2e9f0]"
              } mx-auto px-5 py-3 shadow-lg rounded-xl border border-blue-400 text-sm`}
              style={{
                position: "absolute",
                top: `${virtualRow.start}px`,
                height: `${virtualRow.size - itemMargin}px`, // Adjust height to account for margin
                width: "calc(100% - 2rem)", // Adjust width considering container padding
                left: "1rem", // Adjust to align with container padding
                // Debugging styles

                marginBottom: `${itemMargin}px`, // Add margin between items
              }}
            >
              {/* Card Header */}
              <div className="text">
                <h2 className="text-md text-black flex justify-between items-center">
                  <span className="flex items-center">
                    <p className="font-semibold bg-slate-50 text-black rounded-xl w-9 h-9 flex items-center justify-center text-center">
                      {i?.email?.slice(0, 1)}
                    </p>
                    <div className="ml-2">
                      <p className="font-semibold">{i.Email}</p>
                      <p className="text-xs text-[#914A81]">
                        @{i?.Email?.split("@")[0]}
                      </p>
                    </div>
                  </span>
                  <span className="text-right">
                    <div className="flex items-center space-x-1 my-2 text-[#4A7AAE]">
                      <CiCalendarDate className="text-xl" />
                      <span className="border-r border-solid border-black pr-2">
                        {date}
                      </span>
                      <IoTimeOutline className="text-lg" />
                      <span>{time}</span>
                    </div>
                  </span>
                </h2>
              </div>

              <div className="py-1">
                <div className="text-sm text-gray-800 border-b-2 pb-2 border-dotted border-gray-400">
                  <p className="text-sm text-gray-600 mt-2">{i.Text}</p>
                </div>
              </div>

              {/* Card Footer */}
              <div className="text-right">
                <div className="flex justify-end items-center text-gray-600">
                  {i?.key && (
                    <div className="bg-[#4A7AAE] flex items-center p-1  rounded-full text-white">
                      <span className="">
                        {" "}
                        <TiAttachmentOutline className=" text-xl " />
                      </span>
                    </div>
                  )}
                  {(Rights?.some((i)=>i==element.Email )) && (
                    <span
                      onClick={async () => {
                        await DeleteAlert(
                          `${URL}/Comment/DeleteComments/${i?.Id}`
                        );
                      }}
                      className="flex items-center justify-center"
                    >
                      <MdDelete className="w-4 h-4 cursor-pointer text-red-500" />
                    </span>
                  )}
                </div>
                
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
