import axios from "axios";
import { store } from "../Slices";


const callApi = async (
  endpoint,
  method,
  payload,
  malter = false,
  objnew,
  externalEmailObj,
  loginToken
) => {
  // Determine the token
  const token = loginToken || store.getState().auth.auth.token || null;

  // Set the authorization headers
  const authHeaders = token
    ? {
        Authorization: `Bearer ${token}`,
      }
    : {};


  let configaxios;
  if (malter) {

    const formData = new FormData(); 

    payload.files.forEach((file) => {  
      formData.append("files", file); 
    });
    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key]);
    });
    configaxios = {
      method,
      url: endpoint,
      data: formData,
      headers: {
        Accept: "*/*",
        "Content-Type": "multipart/form-data",
        ...authHeaders,
      },
    };
  } else {
    configaxios = {
      method,
      url: endpoint,
      data: payload,
      headers: {
        Accept: "*/*",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Access-Control-Max-Age": "6000",
        "Access-Control-Allow-Headers": "*",
        ...authHeaders,
      },
    };

    // Set responseType to "blob" if 'objnew' is true
    if (objnew) {
      configaxios.responseType = 'blob';
    }
  }
  return new Promise((resolve, reject) => {
    axios(configaxios)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });
};

const callPublicApi = (endpoint, method, payload) => {
  const configaxios = {
    method,
    url: `${process.env.REACT_APP_AuthenticationService}${endpoint}`,
    data: payload,
    headers: {
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "Access-Control-Max-Age": "6000",
      "Access-Control-Allow-Headers": "*",
    },
  };
  return new Promise((resolve, reject) => {
    axios(configaxios)
      .then((res) => {
        if (res.data.length > 0) {
          resolve(res.data);
        } else if (Object.keys(res.data).length > 0) {
          resolve({ data: [res.data] });
        }
      })
      .catch((error) => {
        // Handle errors in a similar manner to the other callApi function
        // ...
        reject({
          message: "Something went wrong, Please re-login",
          statusCode: 500,
        });
      });
  });
};

export { callApi, callPublicApi };
