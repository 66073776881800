import React, { useEffect, useState } from "react";
import { callApi } from "../common/Apicall";
import { useSelector } from "react-redux";
import ScreenLoader from "../common/ScreenLoader";

const TaskList = ({ setTask }) => {
  const URL = process.env.REACT_APP_URL;
  const {
    data: [element],
  } = useSelector((state) => state.data.userInfo.data);
  const [dataModal, setDataModal] = useState([]);

  const [Loader, setLoader] = useState(false);
  const GetbyTaskId = () => {
    callApi(`${URL}/InternalTask/GetTaskByUserid/${element.Id}`, "get")
      .then((response) => {
        setDataModal(response);

        setLoader(false);
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        setLoader(false);
      });
  };

  useEffect(() => {
    GetbyTaskId();
  }, []);

  return (
    <>
      {" "}
      {Loader && <ScreenLoader />}
      <div className="relative flex flex-col text-gray-700 bg-white shadow-md border-2 rounded-xl bg-clip-border">
        <nav className="flex min-w-[340px] max-h-[250px] flex-col gap-1 p-2 font-sans text-base  overflow-y-auto font-normal text-blue-gray-700">
          {dataModal?.map((task, index) => (
            <div
              key={index}
              role="button"
              onClick={() => {
                setTask({ ...task, taskno: task.id });
              }}
              className="flex items-center w-full px-4 py-1 leading-tight transition-all
             rounded-lg outline-none bg-blue-gray-50/50 text-start text-blue-gray-700
              hover:bg-gray-300 hover:text-black border-b
               focus:bg-blue-900 focus:text-white active:bg-[#2f5496] active:text-white"
            >
              <span className=" font-bold">{index + 1} :</span>
              <span className="ml-3">{task.Subject}</span>
            </div>
          ))}
        </nav>
      </div>
    </>
  );
};

export default TaskList;
