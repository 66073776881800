import * as signalR from "@microsoft/signalr";

export const initiateSignalR = () => {
  const URL = process.env.REACT_APP_URL_webHook;
  let connect = new signalR.HubConnectionBuilder()
  
    .withUrl(`${URL}/chathub`, {
      
      skipNegotiation: true,
      transport: signalR.HttpTransportType.WebSockets
    })
    .withAutomaticReconnect()
    .build();

  connect.start()
    .then(() => console.log(""))
    .catch(err => console.error("Connection failed: ", err));

  return connect;
};


