import React from "react";
import CustomInput from "../common/CustomInput";
import CustomButton from "../common/CustomButton";
import SelectWithSearch from "../common/commonSelect";

const SearchFilters = ({
  state,
  handleChange,
  toggleActive,
  onSearch,
  onClear,
  Practice,
  Department,
  Team,
  Assign,
  Priority,
  Category,
  Status,
}) => (
  <div className="mr-5 ml-5 mt-2 font-sans" onClick={() => toggleActive(false)}>
    <div className="grid md:grid-cols-4 gap-3 sm:grid-cols-2">
      <div className="mb-0">
        <CustomInput
          id="inputField"
          onChange={handleChange}
          value={state.importModel.id}
          type="number"
          label={"Ticket Number"}
          placeholder={'Search Ticket By No...'}
          name="id"
        />
      </div>
      <SelectWithSearch
        label="Practice Name"
        name="practiceId"
        value={state.importModel.practiceId}
        onChange={handleChange}
        options={Practice?.value}
      />
      <SelectWithSearch
        label="Department"
        name="departmentId"
        value={state.importModel.departmentId ?? 0}
        onChange={handleChange}
        options={Department?.value}
      />
      <SelectWithSearch
        label="Team"
        name="teamId"
        value={state.importModel.teamId ?? 0}
        onChange={handleChange}
        options={Team?.value}
      />
      <SelectWithSearch
        label="Assignee"
        name="assigneeId"
        value={state.importModel.assigneeId ?? 0}
        onChange={handleChange}
        options={Assign?.value}
      />
      <SelectWithSearch
        label="Priority"
        name="priorityId"
        value={state.importModel.priorityId ?? 0}
        onChange={handleChange}
        options={Priority?.value}
        optionExtraclass={"bg-white"}
      />
      <SelectWithSearch
        label="Status"
        name="statusId"
        value={state.importModel.statusId ?? 0}
        onChange={handleChange}
        options={Status?.value}
      />
       <SelectWithSearch
        label="Category"
        name="categoryId"
        value={state.importModel.categoryId ?? 0}
        onChange={handleChange}
        options={Category?.value}
      />
    </div>
    <div className="grid grid-cols-4 gap-4 mt-1">
      <CustomInput
        label="From Date"
        name="createdFrom"
        onChange={handleChange}
        value={state.importModel.createdFrom}
        type={"date"}
      />
      <CustomInput
        label="To Date"
        name="createdTo"
        onChange={handleChange}
        value={state.importModel.createdTo}
        type={"date"}
      />
      <div className="flex mt-7 col-span-2 justify-end items-center pb-1">
        <div className="">
          <CustomButton onClick={onSearch} label="Search" />
        </div>
        <div className="px-3">
          <CustomButton onClick={onClear} buttonType="cancel" label="Clear" />
        </div>
      </div>
    </div>
  </div>
);

export default SearchFilters;
