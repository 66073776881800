import React from "react";
import Table from "../common/CustomDataGrid/Table";


const Taskindex = ({ columns, state,handlePerRowsChange,setPagination,pagination }) => (
  
  <div className="mt-2 px-2">
   
    <div className="mt-2">
    <Table
            isShowPagination={true}
            getPaginationChangeValue={handlePerRowsChange}
            totalCount={state?.totalRows}
            pagination={pagination}
            data={state.rows || []}
            columns={columns}
            setPagination={setPagination}
           
           
          />
    </div>
  </div>
);

export default Taskindex;
