import React from "react";
const inputTypes = {
  default: "border rounded w-full px-[7px] py-[3px] h-7  font-sans  leading-tight focus:outline-none focus:shadow-outline ",
  radio: "outline-none px-2 py-1 text-base border border-borderInput  ",
};
const CheckBox = ({ checked, onChange, ...props }) => {
  return (
    <div
      onClick={() => {
        if (onChange)
          onChange({
            target: {
              ...props,
              type: "checkbox",
              checked: !checked,
            },
          });
      }}
      className="w-5 rounded-sm h-5 flex justify-center items-center border-primaryColor border"
    >
      <span
        className={`w-3 h-3 ${checked ? "bg-primaryColor " : ""} inline-block`}
      ></span>
    </div>
  );
};
const CustomInput = ({ label, inputType,required, type,extraClass, ...props }) => {
  return (
    <div
      className={`flex  ${
        ["radio", "checkbox"].includes(type)
          ? "flex flex-row-reverse w-fit items-center "
          : " flex-col  w-full"
      }`}
    >
      {label && (
        <label
          htmlFor={label}
        
          className={`
          ${required && "required"}
          ${
            ["radio", "checkbox"].includes(type) ? "ml-2 mb-0 min-w-[63px] font-sans text-sm" : "font-sans mb-1 text-sm"
          }`}
        >
          {label}
        </label>
      )}
      {type === "checkbox" ? (
        <CheckBox {...props} />
      ) : (
        <input
          id={label}
          
          type={type || "text"}
          {...props}
          className={`${inputTypes[inputType] || inputTypes.default} ${extraClass} disabled:bg-gray-300`}
        />
      )}
    </div>
  );
};

export default CustomInput;
