import React, { useEffect, useState } from "react";

import { toast } from "react-hot-toast";

import * as XLSX from "xlsx"; // Import the xlsx library
import { callApi } from "./Apicall";
import ScreenLoader from "./ScreenLoader";
// const UR = process.env.REACT_APP_URL + "/documents";
const URL = process.env.REACT_APP_URL;
const ViewDocument = ({ modalRow }) => {
  const [fileData, setFileData] = useState(null);
  const [fileUrl, setFileUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true); 
  const [fileType, setFileType] = useState("");
  const [hasRendered, setHasRendered] = useState(false); 

  const display = async (mimeType, a) => {
    try {
      const res = await callApi(
        `${URL}/File/DownloadFiles/${modalRow.key}`,
        "get",
        modalRow,
        false,
        true
      );
      const blob = new Blob([res], { type: mimeType });
      if (a === "xlsx") {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheetName = workbook.SheetNames[0];
          const sheetData = XLSX.utils.sheet_to_json(
            workbook.Sheets[firstSheetName],
            { header: 1 }
          );
          const maxLength = Math.max(...sheetData.map((array) => array.length));
        
          setFileData(
            sheetData.map((arr) =>
              arr.length !== maxLength
                ? new Array(maxLength)
                    .fill("")
                    .map((r, index) =>
                      [false, "", null].includes(arr[index]) || arr[index]
                        ? arr[index]
                        : ""
                    )
                : arr
            )
          );
          setIsLoading(false);
        };
        reader.readAsArrayBuffer(blob);
      } else {
        const url = window.URL.createObjectURL(blob);
        setFileUrl(url);
        setIsLoading(false); 
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      setIsLoading(false); 
    }
  };

  useEffect(() => {
    let a = "";
    let mimeType = "";
    if (modalRow && !hasRendered) {
      const fileExtension = modalRow.ext.toLowerCase().split('.')[1]??modalRow.ext.toLowerCase();    
      if (fileExtension === "pdf") {
        mimeType = "application/pdf";
        a = "pdf";
        setFileType("pdf");
        setHasRendered(true); 
      } else if (fileExtension === "png") {
        mimeType = "image/png";
        a = "png";
        setFileType("png");
      } else if (fileExtension === "docx" || fileExtension === "doc") {
        mimeType = "application/msword";
        a = "docx";
        setFileType("docx");
      } else if (fileExtension === "xlsx" || fileExtension === "xls") {
        mimeType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        a = "xlsx";
        setFileType("xlsx");
      } else {
        toast.error("Unsupported file format.");
        setIsLoading(false);
        return;
      }

      display(mimeType, a);
    }
  }, []);

  return (
    <div className="bg-lightGray mt-auto p-3 py-5">
      {isLoading && <ScreenLoader />}
      {["pdf", "docx", "xlsx"].includes(fileType) ? (
        fileType === "xlsx" ? (
          <table className="min-w-full h-16 divide-y divide-gray-200">
            <tbody className="bg-white divide-y divide-gray-200">
              {fileData &&
                fileData?.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    className={rowIndex % 2 === 0 ? "bg-gray-50" : ""}
                  >
                    {row.map((cell, cellIndex) => (
                      <td
                        key={cellIndex}
                        className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                      >
                        {cell}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        ) : (
          
          <iframe
            src={fileUrl}
            title="Embedded Document"
            width="1100"
            height="600"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        )
      ) : fileType === "png" ? (
        <img
          src={fileUrl}
          alt="EmbeddedImage"
          style={{ width: "100%", height: "600px" }}
        />
      ) : (
        <div>Unsupported file format</div>
      )}
    </div>
  );
};

export default ViewDocument;
