import { FaCodeBranch, FaUsers } from "react-icons/fa";
import {
  FcBusinessman,
  FcCustomerSupport,
  FcDataSheet,
  FcDepartment,
  FcStatistics,
} from "react-icons/fc";
import { BiSolidCategoryAlt } from "react-icons/bi";
import { useSelector } from "react-redux";

const SideBarOptions = () => {
  const practice = useSelector((state) => state?.data?.practice);

  // Utility function to handle undefined or null values
  const safeArray = (value) => Array.isArray(value) ? value : [];

  const sideBarOptions = {
    Practice: {
      name: "practiceID",
      color: "text-[#8ac5ff]",
      value: (() => {
        const values = safeArray(practice?.practice?.value);
        if (values.length > 0) {
          const updatedValues = [...values];
          updatedValues[0] = { ...updatedValues[0], Icon: <FcBusinessman /> };
          return updatedValues;
        }
        return [];
      })(),
    },
    Status: {
      name: "statusId",
      color: "text-[#32b789]",
      value: (() => {
        const values = safeArray(practice?.status?.value);
        if (values.length > 0) {
          const updatedValues = [...values];
          updatedValues[0] = { ...updatedValues[0], Icon: <FcStatistics /> };
          return updatedValues;
        }
        return [];
      })(),
    },
    Category: {
      name: "categoryId",
      color: "text-[#32b789]",
      value: (() => {
        const values = safeArray(practice?.category?.value);
        if (values.length > 0) {
          const updatedValues = [...values];
          updatedValues[0] = { ...updatedValues[0], Icon: <BiSolidCategoryAlt /> };
          return updatedValues;
        }
        return [];
      })(),
    },
    Source: {
      name: "source",
      color: "text-[#8ac5ff]",
      value: [
        { label: "High", value: 7, Icon: <FaCodeBranch /> },
        { input: true }, 
      ],
    },
    "Target Date": {
      name: "targetDate",
      color: "text-[#8ac5ff]",
      value: [
        { label: "High", value: 8, Icon: <FcDataSheet /> },
        { input: true },
      ],
    },
    Department: {
      name: "departmentID",
      color: "text-[#8ac5ff]",
      value: (() => {
        const values = safeArray(practice?.department?.value);
        if (values.length > 0) {
          const updatedValues = [...values];
          updatedValues[0] = { ...updatedValues[0], Icon: <FcDepartment /> };
          return updatedValues;
        }
        return [];
      })(),
    },
    Team: {
      name: "teamID",
      color: "text-[#8ac5ff]",
      value: [
        { Icon: <FaUsers /> },
        ...safeArray(practice?.team?.value),
      ],
    },
    Assignee: {
      name: "assigneeID",
      color: "text-[#8ac5ff]",
      value: (() => {
        const values = safeArray(practice?.assignee?.value);
        if (values.length > 0) {
          const updatedValues = [...values];
          updatedValues[0] = { ...updatedValues[0], Icon: <FaUsers /> };
          return updatedValues;
        }
        return [];
      })(),
    },
    Priority: {
      name: "priorityId",
      color: "text-[#a86a5e]",
      value: (() => {
        const values = safeArray(practice?.priority?.value);
        if (values.length > 0) {
          const updatedValues = [...values];
          updatedValues[0] = { ...updatedValues[0], Icon: <FaUsers /> };
          return updatedValues;
        }
        return [];
      })(),
    },
    Reporter: {
      name: "reporterID",
      color: "text-[#8ac5ff]",
      value: [
        { label: "High", value: 21, Icon: <FcCustomerSupport /> },
      ],
    },
  };

  return sideBarOptions;
};

export default SideBarOptions;
