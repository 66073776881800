import React from "react";

export const TextPage = () => {
  const taskItems = [
    "Task Assignments",
    "Event Day Operations",
    "Tracking and Monitoring",
    "Collaboration and Communication",
    "Time Management",
    "Attendance Analysis",
    "Performance Metrics",
  ];

  // Colors for the bullets
  const bulletColors = [
    "#6df2ec", // Light blue
    "#89f78d", // Light green
    "#f79f97", // Light red
    "#b593f5", // Light purple
    "#6df2ec", // Light blue
    "#89f78d", // Light green
    "#f79f97", // Light red
  ];

  return (
    <div className="flex flex-col py-2 mt-2 px-14 w-[480px] h-[390px] relative space-y-3">
      {taskItems.map((item, index) => (
        <span
          key={index}
          className="text-white flex items-center justify-start w-full 
              text-[15px] tracking-widest font-light font-sans  "
          style={{}}
        >
          <span
            className="text-3xl font-bold me-6 pe-4"
            style={{ color: bulletColors[index] }}
          >
            •
          </span>
          {item}
        </span>
      ))}
    </div>
  );
};
