import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import { RiDeleteBin3Fill } from "react-icons/ri";
import CustomButton from "./CustomButton";

const FileUploader = ({ files, setFiles, Taskid, Commentsid }) => {
  const allowedExtensions = [
    "pdf", "png", "jpg", "jpeg", "gif", "svg", 
    "doc", "docx", "xls", "xlsx", "txt", 
    "ppt", "pptx"
  ];

  const [selectedFile, setSelectedFile] = useState(null); // for modal popup

  const getFilesWebkitDataTransferItems = (event) => {
    const fileList = event.target.files;
    const newFiles = Array.from(fileList)
      .map((file) => {
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (!allowedExtensions.includes(fileExtension)) {
          toast.error(`File '${file.name}' has an unsupported format.`);
          return null;
        }
        const existingFile = files.find((f) => f.name === file.name);
        if (existingFile) {
          toast.error(`File '${file.name}' has already been selected.`);
          return null;
        }
        return {
          name: file.name,
          extension: fileExtension,
          path: null,
          isCommentFile: false,
          file: file,
        };
      })
      .filter(Boolean);

    setFiles([...files, ...newFiles]);
  };

  const refc = useRef(null);

  const triggerFileSelect = () => {
    if (refc.current) {
      refc.current.value = "";
      refc.current.click();
    }
  };

  const removeFile = (indexToRemove) => {
    setFiles(files.filter((_, index) => index !== indexToRemove));
  };

  const openModal = (file) => {
    setSelectedFile(URL.createObjectURL(file));
  };

  const closeModal = () => {
    setSelectedFile(null);
  };

  return (
    <div className="flex ">
      <input
        style={{ display: "none" }}
        type="file"
        onChange={getFilesWebkitDataTransferItems}
        multiple
        ref={refc}
      />
      <CustomButton
        label={"Attachments"}
        buttonType="file"
        onClick={triggerFileSelect}
      />
      {files.length > 0 &&
        files.map((el, index) => (
          <div
            key={index}
            className="flex justify-between items-center ml-4 text-black py-1 border-b border-gray-300"
          >
            <span className="w-[100%] pe-1">{el.name}</span>
            <div className="flex space-x-2">
              <button
                className="text-blue-500 cursor-pointer"
                onClick={() => openModal(el.file)}
              >
                View
              </button>
              <i
                className="text-red-700 cursor-pointer"
                onClick={() => removeFile(index)}
              >
                <RiDeleteBin3Fill />
              </i>
            </div>
          </div>
        ))}

      {/* Modal for viewing the file */}
      {selectedFile && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-5 rounded-lg relative max-w-3xl w-full max-h-[90vh] overflow-y-auto">
            <button
              className="absolute top-2 right-2 text-red-600"
              onClick={closeModal}
            >
              ✕
            </button>
            {/* Image Preview */}
            <div className="flex justify-center items-center">
              {selectedFile.endsWith("pdf") ? (
                <iframe
                  src={selectedFile}
                  title="File Preview"
                  className="w-full h-[80vh]"
                />
              ) : (
                <img
                  src={selectedFile}
                  alt="Preview"
                  className="max-h-[80vh] object-contain"
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUploader;
