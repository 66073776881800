import React from "react";
import CustomInput from "../common/CustomInput";
import CustomTextArea from "../common/CustontextArea";
import CustomButton from "../common/CustomButton";
import FileUploader from "../common/Fileuploader";

const TicketForm = ({ saveData,validationModel, state, setState,file,setFile }) => {

  const handelChange = (e) => {
    const { value, name } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="mx-5 ">
      <div className="">
        {" "}
        <label className=" text-sm ">Subject *</label>
      </div>
      <div className="pt-3">
        <CustomInput
          extraClass={"h-8  text-base py-1"}
          onChange={handelChange}
          name="subject"
          type="text "
          placeholder="Enter here"
          value={state.subject}
        />
       { validationModel?.subject}
      </div>

      <div className="pt-5">
        {" "}
        <label className=" text-sm mb-1">Description *</label>
      </div>
      <div className="pt-3">
        <CustomTextArea
          onChange={handelChange}
          name="description"
          type="text "
          placeholder="Enter here"
          rows={5}
          value={state.description}
        />
{validationModel?.description}
        <div className="flex justify-between mt-4">

            <FileUploader files={file} setFiles={setFile} />
     

          <div className=" border-gray-300">
            <CustomButton onClick={saveData} label="Create Task" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketForm;
