import React from "react";
import CustomModal from "../common/CustomModal";
import CreateTickect from "../CreateTickect/CreateTickect";
import ConverstionMain from "../ConverstionMain/ConverstionMain";

const AllModals = ({ isModalOpen, onClose, state, isEdit }) => (
  <>
    {isModalOpen.isModalOpenCreatetask && (
      <CustomModal
        title={state.modalRow ? "Update Task" : "Create Task"}
        onClose={() => onClose("isModalOpenCreatetask")}
        ModalBody={
          <CreateTickect
            modalRow={state.modalRow}
            onClose={() => onClose("isModalOpenCreatetask")}
            isEdit={isEdit}
          />
        }
        size={"xxxl"}
      />
    )}
    {isModalOpen.isModalOpenMyTaskList && (
      <CustomModal
        title={"View Task"}
        onClose={() => onClose("isModalOpenMyTaskList")}
        ModalBody={
          <ConverstionMain isEdit={isEdit} modalRow={state.modalRow} />
        }
        size={"xxxl"}
      />
    )}
  </>
);

export default AllModals;
