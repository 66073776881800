import React from "react";
const inputTypes = {
  default: "outline-none px-2 py-1 rounded-md border   ",
};
const CustomTextArea = ({ label, inputType, type, ...props }) => {
  return (
    <div
      className={`flex ${
        ["radio", "checkbox"].includes(type)
          ? "flex flex-row-reverse"
          : " flex-col"
      }`}
    >
      {label && (
        <label
          htmlFor={label}
          className={`${
            ["radio", "checkbox"].includes(type)
              ? "ml-2 font-sans  text-sm"
              : " font-sans mb-1 text-sm"
          }`}
        >
          {label}
        </label>
      )}

      <textarea
        id={label}
        type={type || "text"}
        {...props}
        className={`${inputTypes[inputType] || inputTypes.default}`}
      />
    </div>
  );
};

export default CustomTextArea;
