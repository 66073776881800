

import { toast } from "react-hot-toast";
import { callApi } from "./Apicall";
import Swal from "sweetalert2";

const DeleteAlert = (endPoint) => {
  return new Promise((resolve, reject) => {
    Swal.fire({
      title: "Are you sure you want to delete the record ?",
      icon: "warning",
      confirmButtonColor: "#46A8DF",
      cancelButtonColor: "#383F43",
      denyButtonColor: "#383F43",
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        callApi(endPoint, "get")
          .then((resp) => {
            toast.success("Record Deleted Successfully !");
            resolve(resp);
          })
          .catch((err) => {
            toast.error("Something went wrong !");
            reject(err);
          });
      }
    });
  });
};

export default DeleteAlert;
