import { CiSettings } from "react-icons/ci";
import { FaPlusCircle } from "react-icons/fa";
import MyTaskList from "./TaskList";
import { MdTask } from "react-icons/md";

 const MyTaskButton = ({ activeTab, toggleActive, containerRef, setTask }) => (
    <div className="text-right">
      <button
        ref={containerRef}
        type="button"
        className={`flex items-center text-md px-2 me-2 py-[2px]  font-medium text-gray-900 ${
          activeTab ? "bg-white " : "bg-white "
        } rounded-lg`}
        onClick={() => toggleActive(true)}
      >
        <span className="">My Tasks</span>
        <i className="ml-3 text-xl text-purple-500">
          <MdTask />
        </i>
      </button>
      {activeTab && (
        <div className="absolute z-10 ease-in-out duration-1000 mt-1">
          <MyTaskList setTask={setTask} />
        </div>
      )}
    </div>
  );
  
  const CreateTaskButton = ({ toggleModal }) => (
    <div className="text-right">
      <button
        type="button"
        className={`flex items-center text-md px-2 me-2 mb-1 mt-1 py-[2px] 
           bg-white rounded-lg text-gray-700`}
        onClick={() => toggleModal("isModalOpenCreatetask")}
      >
        <span className="">Create Task</span>
        <i className="ml-3 text-xl text-green-500">
          <FaPlusCircle />
        </i>
      </button>
    </div>
  );
  
  const SettingsButton = ({ toggleModal }) => (
    <div className="text-right">
      <button
        type="button"
        className={`flex items-center text-md px-3 me-2 mb-1 mt-1 py-1 font-medium
              bg-white rounded-lg text-gray-700`}
        onClick={() => toggleModal()}
      >
        <span className="">Settings</span>
        <i className="ml-2 text-2xl text-green-500">
          <CiSettings />
        </i>
      </button>
    </div>
  );
  
  export { SettingsButton, CreateTaskButton, MyTaskButton };