import React, { useEffect, useState } from "react";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import ScreenLoader from "./components/common/ScreenLoader";
import Login from "./components/Login/Login";
import { callApi } from "./components/common/Apicall";
import { addData } from "./components/Slices/DataSlice";
import SearchedTickect from "./components/SearchedTickect/SearchedTickect";
import Main from "./components/AddScreens/Main/Main";
import { Toaster } from "react-hot-toast";
import Dashboard from "./components/Header/Dashboard/Dashboard";
import { Activescreen } from "./components/Slices/Activescreen";

const App = () => {
  const initialImportModel = {
    perPage: 10,
    pageNo: 1,
    practiceId: null,
    totalPages: 0,
    totalCount: 0,
    departmentId: null,
    teamId: null,
    totalRows: "",
    id: '',
    priorityId: "",
    categoryId:'',
    name:'',
    statusId: "",
    createdFrom:'',
    createdTo:''
  };
  const [state, setState] = useState({
    importModel: initialImportModel,
    addPopup: "",
    totalRows: 50,
    rows: [],
    roles: [],
    modalRow: null,
  });
  const dispatch = useDispatch();
  const activeScreen = useSelector((state) => state.activeScreen);
  const message = useSelector((state) => state.data?.userInfo?.data);
  const [loader, setLoader] = useState(false);

  const components = {
    SearchedTickect: SearchedTickect,
    Main: Main,
    Dashboard: Dashboard,
  };

  const handleChange = (name, value) => {
    setState({
      ...state,
      importModel: {
        ...state.importModel,
        [name]: value.toString(),
      },
    });
    if (name == "priorityId") {
      return;
    } else {
      trigger();
    }
  };
  useEffect(() => {
    if (state.importModel.priorityId) {
      trigger();
    }
  }, [state.importModel.priorityId]);
  const trigger = () => {
    console.log("trigger11");
    dispatch(Activescreen("SearchedTickect"));
  };
  const URL = process.env.REACT_APP_URL_DOCUHUB_URL;
  const DisplayComponent = components[activeScreen?.ActiveScreen];

  useEffect(() => {
    if (message?.message === "Login Successfully") {
      getData();
    }
  }, [message]);

  const getData = () => {
    setLoader(true);
    callApi(`${URL}/Department/GetDepartmentDropDown`, "get")
      .then((res) => {
        dispatch(addData({ name: "practice", data: res.sideBarOptions }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoader(false);
      });
  };
  console.log(state);
  return (
    <>
      <Toaster />
      <div className="flex flex-col min-h-screen font-sans bg-[#FFF]">
        {loader && <ScreenLoader />}
        {message?.message === "Login Successfully" && DisplayComponent ? (
          <DisplayComponent
            {...(activeScreen?.ActiveScreen === "SearchedTickect" ||
            activeScreen?.ActiveScreen === "Dashboard"
              ? {
                  handleChange,
                  dashboardstate: { ...state },
                  setdashboardstate: { setState },
                }
              : { payload: null })}
          />
        ) : (
          <Login />
        )}
      </div>
    </>
  );
};

export default App;
