import React, { useEffect, useRef, useState } from "react";
import Card from "../common/Card/ParentCard.js/Card";
import Sidebar from "../sidebar/Sidebar";
import { callApi } from "../common/Apicall";
import CustomButton from "../common/CustomButton";
import { toast } from "react-hot-toast";
import ScreenLoader from "../common/ScreenLoader";
import { useSelector } from "react-redux";
import uploadFile from "../common/fileApiFunction";

const ConversationMain = ({ modalRow, isEdit }) => {
  const {
    data: [element],
  } = useSelector((state) => state.data.userInfo.data);
  const URL = process.env.REACT_APP_URL;
  const [loader, setLoader] = useState(false);
  const [file, setFile] = useState([]);
  const object = {
    number: 0,
    subject: "",
    description: "",
    statusId: 0,
    priorityId: 0,
    userID: 0,
    practiceID: 0,
    InternalFiles: [],
    internalComments: [],
    categoryId:0,
    departmentID: 0,
    teamID: 0,
    isActive: true,
    assigneeID: 0,
    createdDate: null,
    updatedDate: null,
    internalAssigneeID: 0,
    id: 0,
    errorMessage: "",
    newID: 0,
    targetDate: "",
  };

  const refc = useRef(null);
  const [state, setState] = useState({...modalRow,...object});

  useEffect(() => {
    if (modalRow) {
      GetbyTaskId(modalRow.taskno);
    }
  }, [modalRow]);

  useEffect(() => {
    smoothScroll();
  }, [state]);

  const smoothScroll = () => {
    if (refc.current) {
      refc.current.scrollTop = refc.current.scrollHeight;
    }
  };

  const GetbyTaskId = (id) => {
    setLoader(true);
    callApi(`${URL}/InternalTask/GetTaskById/${id}`, "get")
      .then((response) => {
        setState({ ...state, ...response[0] });
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error Getting data:", error);
        setLoader(false);
      });
  };

  const saveData = () => {
    setLoader(true);
    callApi(`${URL}/InternalTask/SaveTasks`, "post", state)
      .then((response) => {
        if (file.length > 0) {
          uploadFile({ ...file, InternalTaskId: response.id, CommentId: 0 });
        }
        toast.success("Task updated successfully");
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        toast.error("Failed to save data");
        setLoader(false);
      });
  };

  return (
    <>
      {loader && <ScreenLoader />}
      <div className="flex">
        <div className=" bg-sideNavbg w-[310px]  ">
          <Sidebar state={state} isEdit={isEdit} setState={setState} />
        </div>

        <div className=" w-full ml-1 p-4 h-[650px] ">
          {/* Header */}
          <div className="overflow-y-auto h-[590px]" ref={refc}>
            <Card
              setFile={setFile}
              file={file}
              modalRow={{ ...state}}
              smoothScroll={smoothScroll}
              component={
                <div className="border-gray-300">
                  <CustomButton onClick={saveData} label="Save" />
                </div>
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ConversationMain;
