import React from "react";
import Index from "../Header/Index";
import image from "../../assets/Images/image.png";
import image2 from "../../assets/Images/Group 173.png";
export const Header = ({ setTask }) => {
  return (
    <div>
      {" "}
      <div className="flex font-sans">
        <div
          className="w-2/12 h-7vh bg-sideNavbg 
    text-center flex justify-center items-center text-white "
        >
          <div className="flex">
            <img src={image2} alt="image_" />
            <img src={image} alt="image_" />
          </div>
        </div>
        <div className="w-10/12">
          <Index setTask={setTask} />
        </div>
      </div>
    </div>
  );
};
